// import { Routes, Route } from 'react-router-dom';
import DavidmaiButton1 from '../../components/templates/buttons/davidmai-button-1/davidmai-button-1.component';
import DavidmaiButton2 from '../../components/templates/buttons/davidmai-button-2/davidmai-button-2.component';
import DavidmaiCard2 from '../../components/templates/cards/davidmai-card-2/davidmai-card-2.component';

const Services = () => {
    return (
        // <Routes>
        //     <Route index element={<ServicesPreview />} />
        //     <Route path=':service' element={<Service />} />
        // </Routes>

        <div>
            Services

            <div className='navbars-container'>
                <div>Navbars</div>
            </div>

            <div className='buttons-container'>
                <div>buttons</div>
                <DavidmaiButton1 />
                <DavidmaiButton2 />
            </div>

            <div className='cards-container'>
                <div>Cards</div>
                <DavidmaiCard2 />
            </div>

            <div className='inputs-container'>
                <div>Inputs</div>
            </div>

            <div className='footers-container'>
                <div>Footers</div>
            </div>
        </div>
    )
}

export default Services;