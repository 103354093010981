import ContactForm from "../../components/contact-form/contact-form.components";

const Contact = () => {
    return (
        <div>
            <ContactForm />
        </div>
    )
}

export default Contact;