import { useState } from 'react';

import {
    ShineButtonContainer,
    ShineButton,
    ColorSelector,
    CustomButtonWrapper,
    CustomText,
    CustomButtonContainer
} from './muhilan-button.styles';

const MuhilanButton = () => {
    
    const theme = {
        color1: "#00fffc",
        color2: "#e819ef",
        color3: "#67dc1e",
        color4: "#dc1e67",
        color5: "#dc931e",
        color6: "#d9e95e"
    }

    const [color, setColor] = useState("#000000");

    const handleColor = (e) => {
        setColor(e.target.value);
    }
    
    return (
        <ShineButtonContainer>
            {/* Colors can be passed directly in the theme and will work.
                example: theme={"#FFF"}
             */}
            <ShineButton theme={theme.color1}>Like</ShineButton>
            <ShineButton theme={theme.color2}>Follow</ShineButton>
            <ShineButton theme={theme.color3}>Subscribe</ShineButton>
            <ShineButton theme={theme.color4}>Share</ShineButton>
            <ShineButton theme={theme.color5}>Comment</ShineButton>
            <ShineButton theme={theme.color6}>Button</ShineButton>
            <CustomButtonWrapper>
                <CustomText>Select a color</CustomText>
                <CustomButtonContainer>
                    <ColorSelector type='color' value={color} onChange={handleColor} />
                    <ShineButton theme={color}>Custom</ShineButton>
                </CustomButtonContainer>
                <CustomText>{color}</CustomText>
            </CustomButtonWrapper>
            
        </ShineButtonContainer>
    )
}

export default MuhilanButton;