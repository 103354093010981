import './section-header.styles.scss';

const SectionHeader = ({ title, text }) => {
    return (
        <div className='section-header-container'>
            <h1 className='section-header-title'>{title}</h1>
            <p className='section-header-text'>{text}</p>
        </div>
    )
}

export default SectionHeader;