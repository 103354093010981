import './experience.styles.scss';
import experiences from '../../assets/data-arrays/experience';

const Experience = () => {
    return (
        <div className='experience-container container'>

            <h3 className='exp-title'>Work Experience</h3>

            {
                experiences.map ( (experience) => (
                    <div className='experience' key={experience.id}>
                        <div className='header'>
                            <h4>{experience.title}</h4>
                            <h4>{experience.location}</h4>
                            <h4>{experience.startDate} to {experience.endDate}</h4>
                        </div>
                        <ul>
                            {experience.tasks.map( (task, index) => (
                                <li key={index}>{task}</li>
                            ))}
                        </ul>
                    </div>
                ))
            }
        </div>
    )
}

export default Experience;