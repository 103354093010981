import './loading-meatballs.styles.scss';

const LoadingMeatballs = () => {
    return (
        <span className='loader'>
            <span className='loader_ball'></span>
            <span className='loader_ball'></span>
            <span className='loader_ball'></span>
        </span>
    )
}

export default LoadingMeatballs;