import './menu-blur.styles.scss';

const MenuBlur = () => {
    return (
        <div className='menu-blur-container'>
            <ul>
                <li>Home</li>
                <li>About</li>
                <li>Contact</li>
                <li>Tools</li>
            </ul>
        </div>
    )
}

export default MenuBlur;