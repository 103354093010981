import './modal.styles.scss';

const Modal = () => {
    return (
        <div className='modal-container'>
            <button className='open-modal-btn'>Open Modal</button>
            
            <div className='modal-overlay'>
                <div className='modal'>
                    <div className='modal-header'>
                        <h2>Modal Title</h2>
                        <button className='close-modal-btn'>&times;</button>
                    </div>
                    <div className='modal-content'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam autor, eros at posuere fringilla, turpis tortor efficitur velit, at fringilla tortor magna id quam.</p>
                    </div>
                </div>
            </div>
            <div className='attribute'>
                Created by
                <a href='https://instagram.com/codewithshobhit?igshid=MzNlNGNkZWQ4Mg=='> codewithshobhit</a>
            </div>
        </div>
    )
}

export default Modal;