import Button from '../button/button.components';
import './project-card.styles.scss';

const ProjectCard = ({id, type, title, text, liveLink, codeLink, imageUrl}) => {
    let inverted = (id % 2 !== 0) ? true : false;
    
    return (
        <div className={`project-card ${inverted ? 'projects__list-item--inv' : ''}`}>
            <div className='projects__list-item-details'>
                <div className='projects__list-item-img-cont'>
                    <img className='project-image' src={imageUrl} alt='pic'/>
                </div>
            </div>
            
            <h3 className='project-title'>{title}</h3>
            <p className='project-text'>
                <span>
                    {text}
                </span>    
            </p>
            <div className='button-container'>
                <Button buttonType={type === 'app' ? 'app' : 'live-link'} link={liveLink} />
                <Button buttonType='code-link' link={codeLink} />
            </div>
            
        </div>
    );
}

export default ProjectCard;