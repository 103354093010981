import './glowing-menu.styles.scss';

const GlowingMenu = () => {
    return (
        <div className='glowing-menu'>
            <section>
                <ul>
                    <li>
                        <a href='/projects/play' data-text='Home'>Home</a>
                    </li>
                    <li>
                        <a href='/projects/play' data-text='About'>About</a>
                    </li>
                    <li>
                        <a href='/projects/play' data-text='Services'>Services</a>
                    </li>
                    <li>
                        <a href='/projects/play' data-text='Team'>Team</a>
                    </li>
                    <li>
                        <a href='/projects/play' data-text='Contact'>Contact</a>
                    </li>
                </ul>
            </section>
        </div>
    );
}

export default GlowingMenu;