import skills from "../../assets/data-arrays/skills";
import './skill-icons.styles.scss';

const SkillIcons = () => {
    return (
        <div className='skills-icon-container container'>
            <ul>
                {skills.map( (skill) => (
                    skill.icon !== '' && // if skill.icon is an empty string, do not create a li element.
                    <li key={skill.id} className='skill-icon'>
                        <i className={`devicon-${skill.icon}-plain`} ></i>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default SkillIcons;