import './select-box.styles.scss';

const SelectBox = ({selectedCategory, categoryChange}) => {
    return (
        <div className='select-box'>
            <select onChange={categoryChange} className='selection'>
                <option value=''>Select a category</option>
                <option value='auto'>Auto</option>
                <option value='business & career'>Business and Career</option>
                <option value='clothing & accessories'>Clothing and Accessories</option>
                <option value='computers & electronics'>Computer and Electronics</option>
                <option value='department store'>Department Store</option>
                <option value='entertainment'>Entertainment</option>
                <option value='family'>Family</option>
                <option value='financial services'>Financial Services</option>
                <option value='food & drink'>Food and Drink</option>
                <option value='games & toys'>Games and Toys</option>
                <option value='gift & flowers'>Gifts and Flowers</option>
                <option value='health & beauty'>Health and Beauty</option>
                <option value='hobbies & collectibles'>Hobbies and Collectibles</option>
                <option value='home & living'>Home and Living</option>
                <option value='internet & online'>Internet and Online</option>
                <option value='mature/adult'>Mature/Adult</option>
                <option value='miscellaneous'>Miscellaneous</option>
                <option value='office'>Office</option>
                <option value='sports & fitness'>Sports and Fitness</option>
                <option value='telecommunications'>Telecommunications</option>
                <option value='travel'>Travel</option>
            </select>
        </div>
    )
}

export default SelectBox;