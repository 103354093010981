import projects from "../../assets/data-arrays/projects";
import ProjectCard from "../../components/project-card/project-card.components";

import './projects.styles.scss';

import BackToTop from "../../components/back-to-top/back-to-top.components";
import SectionHeader from "../../components/section-header/section-header.components";

const Project = () => {
    return (
        <div className='project-page-container'>
            <SectionHeader 
                title='My Projects 🛠️'
                text='Discover my diverse collection of projects, showcasing my passion for coding and creativity. From web development to mobile apps and more, each project reflects my commitment to delivering innovative solutions and pushing boundaries. Explore the unique features, elegant designs, and meticulous craftsmanship that make these projects stand out. Dive into the world of possibilities and get inspired by the possibilities that await.'
            />

            <hr className='divider' />

            { projects.map((project) => (
                <ProjectCard
                    key={project.id}
                    id={project.id}
                    type={project.type}
                    title={project.title}
                    text={project.text}
                    imageUrl={project.imageUrl}
                    liveLink={project.liveLink}
                    codeLink={project.codeLink}
                />
                
                ))
            }

            <BackToTop title='Back to Top' />
        </div>
    );
}

export default Project;