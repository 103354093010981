import { Outlet } from "react-router-dom";
import React, { useState } from "react";

import Directory from '../../components/directory/directory.components';
import SearchBox from '../../components/search-box/search-box.components';

import categoriesArray from '../../assets/data-arrays/categories';
import './shop.styles.scss';
import SelectBox from "../../components/select-box/select-box.components";
import BackToTop from "../../components/back-to-top/back-to-top.components";

const Shop = () => {
    const [categories] = useState(categoriesArray);
    const [searchField, setSearchField] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const onSearchChange = (event) => {
        setSearchField(event.target.value);
    }

    const onCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const filteredCategories = categories.filter(category => {
        if(!selectedCategory) {
            return category.title.toLowerCase().includes(searchField.toLowerCase());
        }
        
        return category.title.toLowerCase().includes(searchField.toLowerCase()) && category.type.includes(selectedCategory);
    });

    return (
        <div className='shop-container'>
            <p className='disclosure'>Disclosure: I am an affiliate for the products that can be purchased through the corresponding links. This means that I may receive compensation if you purchase a product through one of my links.</p>
            
            <div className='search-inputs'>
                <SelectBox categoryChange={onCategoryChange}/>
                <SearchBox searchChange={onSearchChange}/>
            </div>
            
            <Directory categories={filteredCategories} />
            
            <Outlet />

            <BackToTop title='Back to Top' />
        </div>
    );
}

export default Shop;