import styled from 'styled-components';

const background = "#252525";

// Moved the colors to the muhilan-button.component file.
// Now passed as a prop to determine the color.
// const color1 = "#00fffc";
// const color2 = "#e819ef";
// const color3 = "#67dc1e";
// const color4 = "#dc1e67";
// const color5 = "#dc931e";
// const color6 = "#d9e95e";

export const ShineButtonContainer = styled.div`
    background-color: ${background};
    width: 80%;
    min-height: 25vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px;
    font-family: "Raleway", sans-serif;
`;

export const ShineButton = styled.button`
    padding: 8px 20px;
    width: 200px;
    height: 50px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 0;
    text-transform: uppercase;
    margin: 25px;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid ${props => props.theme};

    &::before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(120deg, transparent, ${props => props.theme}, transparent);
        transform: translateX(-100%);
        transition: 0.6s;
        position: absolute;
    }

    &:hover {
        background: transparent;
        box-shadow: 0 0 20px 5px ${props => props.theme};
    }

    &:hover::before {
        transform: translateX(100%);
    }

    ${'' /* Added a prop to the button to cut down the ":nth-child(n)" code.
            Keeping for reference, but cut about 60 lines of code.
    */}

    ${'' /* &:nth-child(2) {
        border: 1px solid ${props => props.theme};
    }

    &:nth-child(2)::before {
        background: linear-gradient(120deg, transparent, ${color2}, transparent);
    }

    &:nth-child(2):hover {
        box-shadow: 0 0 20px 5px ${color2};
    }

    &:nth-child(3) {
        border: 1px solid ${color3};
    }

    &:nth-child(3)::before {
        background: linear-gradient(120deg, transparent, ${color3}, transparent);
    }

    &:nth-child(3):hover {
        box-shadow: 0 0 20px 5px ${color3};
    }

    &:nth-child(4) {
        border: 1px solid ${color4};
    }

    &:nth-child(4)::before {
        background: linear-gradient(120deg, transparent, ${color4}, transparent);
    }

    &:nth-child(4):hover {
        box-shadow: 0 0 20px 5px ${color4};
    }

    &:nth-child(5) {
        border: 1px solid ${color5};
    }

    &:nth-child(5)::before {
        background: linear-gradient(120deg, transparent, ${color5}, transparent);
    }

    &:nth-child(5):hover {
        box-shadow: 0 0 20px 5px ${color5};
    }

    &:nth-child(6) {
        border: 1px solid ${color6};
    }

    &:nth-child(6)::before {
        background: linear-gradient(120deg, transparent, ${color6}, transparent);
    }

    &:nth-child(6):hover {
        box-shadow: 0 0 20px 5px ${color6};
    } */}
`;

export const CustomButtonWrapper = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
`;

export const CustomText = styled.p`
    color: white;
    text-align: center;
    font-size: 24px;
`;

export const CustomButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ColorSelector = styled.input`
    
`;
