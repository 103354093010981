import './project-highlight.styles.scss';
import { Link } from 'react-router-dom';

import ProjectCard from '../project-card/project-card.components';

import projects from '../../assets/data-arrays/projects';
import Button from '../button/button.components';
import SectionHeader from '../section-header/section-header.components';

const ProjectHighlight = () => {
    return (
        <div className='project-highlight-container'>
            <SectionHeader 
                title='Projects 🛠️'
                text='Here you will find some of the personal projects that I created with the project details'
            />

            <ProjectCard 
                id={projects[0].id}
                type={projects[0].type}
                title={projects[0].title}
                text={projects[0].text}
                imageUrl={projects[0].imageUrl}
                liveLink={projects[0].liveLink}
                codeLink={projects[0].codeLink}
            />
            <ProjectCard 
                id={projects[1].id}
                type={projects[1].type}
                title={projects[1].title}
                text={projects[1].text}
                imageUrl={projects[1].imageUrl}
                liveLink={projects[1].liveLink}
                codeLink={projects[1].codeLink}
            />
            <ProjectCard 
                id={projects[2].id}
                type={projects[2].type}
                title={projects[2].title}
                text={projects[2].text}
                imageUrl={projects[2].imageUrl}
                liveLink={projects[2].liveLink}
                codeLink={projects[2].codeLink}
            />
            <ProjectCard 
                id={projects[3].id}
                type={projects[3].type}
                title={projects[3].title}
                text={projects[3].text}
                imageUrl={projects[3].imageUrl}
                liveLink={projects[3].liveLink}
                codeLink={projects[3].codeLink}
            />

            <Link to='/projects'>
                <Button buttonType='view-more' link='/projects' />
            </Link>
            
        </div>
    );
}

export default ProjectHighlight;