import './footer.styles.scss';

import instagram from '../../../assets/social-media-icons/instagram.svg';
import twitter from '../../../assets/social-media-icons/twitter.svg';
import youtube from '../../../assets/social-media-icons/youtube.svg';
import github from '../../../assets/social-media-icons/github.svg';
import linkedin from '../../../assets/social-media-icons/linkedin.svg';

const Footer1 = () => {
    return (
        <footer>
            <div className='footer-line'></div>
            <div className='footer-wrapper'>
                <section className='footer-top'>
                    <div className='footer-headline'>
                        <h2>Sign up for our newsletter</h2>
                        <p>Stay up to date with our news and articles</p>
                    </div>
                    <div className='footer-subscribe'>
                        <input
                            type='email'
                            placeholder='Your email'
                        />
                        <button>Sign Up</button>
                    </div>
                </section>
                <div className='footer-columns'>
                    <section className='footer-logo'>
                        <svg xmlns='https://www.w3.org/2000/svg' className='logo' fill='none' viewBox='30 30 126 126'>
                            {/* svg content */}
                        </svg>
                        <h2 class='hide'>Astra</h2>
                    </section>
                    <section>
                        <h3>Product</h3>
                        <ul>
                            <li>
                                <a href='https://www.danielwernz.com' title='API'>API</a>
                            </li>
                            <li>
                                <a href='https://www.danielwernz.com' title='Pricing'>Pricing</a>
                            </li>
                            {/* More items */}
                        </ul>
                    </section>
                    {/* More sections */}
                </div>
                <div className='footer-bottom'>
                    <small>&copy; My Company Ltd. <span id='year'>2023</span> All rights reserved</small>
                    <span className='social-links'>
                        <a href='https://www.instagram.com'>
                            <img src={instagram} alt='Instagram' />
                        </a>
                        <a href='https://www.linked.com'>
                            <img src={linkedin} alt='LinkedIn' />
                        </a>
                        <a href='https://www.twitter.com'>
                            <img src={twitter} alt='Twitter' />
                        </a>
                        <a href='https://www.youtube.com'>
                            <img src={youtube} alt='YouTube' />
                        </a>
                        <a href='https://www.github.com'>
                            <img src={github} alt='GitHub' />
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer1;