import './about.styles.scss';

import SkillIcons from "../../components/skill-icons/skill-icons.components";
import SkillsList from "../../components/skills-list/skills-list.components";
import Experience from "../../components/experience/experience.components";
// import Education from "../../components/education/education.components";
// import Certification from "../../components/certifications/certifications.components";
import SectionHeader from "../../components/section-header/section-header.components";
import Card from '../../components/play/card/card.components';

const About = () => {
    return (
        <div className='about-container'>
            <SectionHeader 
                title='About 👨‍🍳👨‍💻' 
                text='Here you will find more information about me, what I do, and list of my current skills in programming'
            />

            <div className='about-section-container'>
                
                <div className='my-story-container container'>
                    <h3 className='story-title'>My Story</h3>
                    <p>As a former chef with experience working in some of the most prestigious kitchens in country, I developed a keen eye for detail and a passion for excellence. When I graduated from Eastern Florida State College (EFSC) with a Bachelor of Applied Science in Computer Information Systems Technology in 2021, I knew that my culinary background could bring a unique perspective to my new career path.</p>
                    <p>In pursuing my interest in web development, I have completed several online courses with freeCodeCamp, including Responsive Web Design and JavaScript Algorithms and Data Structures. I am currently working through the Zero to Mastery Academy Complete React Developer course to expand my knowledge and skills and gain hands-on experience with building websites and applications.</p>
                    <p>In addition to my work in technology, I founded and served as student president of the National Society of Leadership and Success (NSLS) EFSC Chapter, demonstrating my leadership skills and ability to collaborate with others to achieve a common goal.</p>
                    <p>As an avid scuba diver and currently a Divemaster candidate with Dayo Scuba, I hold several certifications, including Helitrox Decompression Diver, which demonstrates my skills and discipline for decompression dives and using up to 35% helium in scuba cylinders. My experience as a scuba diver has taught me the value of persistence, attention to detail, and the importance of safety, all of which I bring to my professional life.</p>
                    <p>In the long term, I hope to leverage my skills and experience in technology to create solutions that make a positive impact on the world. Whether through developing innovative applications or creating impactful websites, I am committed to using my skills for good and making a difference in the world.</p>
                </div>

                <div className='profile-picture-container'>
                    <Card />
                </div>

                <SkillsList />
                <SkillIcons />
                <Experience />

                {/* Removing for better formatting of page. Keeping to possibly add at a later point with better styling. */}
                {/* <Education />
                <Certification type='Developer' />
                <Certification type='Scuba' /> */}
            </div>  
        </div>
    );
}

export default About;