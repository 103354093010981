import React, { useState } from 'react';
import './landing-page.styles.scss';
import NewsletterSignup from '../newsletter-signup/newsletter-signup.components';

const LandingPage = () => {
    const [showModal, setShowModal] = useState(true);

    const localStorage = window.localStorage;

    const hasVisited = localStorage.getItem('hasVisited');

    const handleClose = () => {
        setShowModal(false);

        localStorage.setItem('hasVisited', true);
    }
    
    return (
        <div>
            <div className='landing-page-container'>            
                <h4 className='section-header'>Hello there 👋</h4>
                <h2 className='section-text'>I'm Daniel Wernz! A Software Developer creating user-centric software solutions for a better digital world.</h2>
            <div className='bubble-container'>
                <div className='bubble'>
                    <span className='dot'></span>
                </div>
                <div className='bubble'>
                    <span className='dot'></span>
                </div> 
                <div className='bubble'>
                    <span className='dot'></span>
                </div> 
                <div className='bubble'>
                    <span className='dot'></span>
                </div> 
                <div className='bubble'>
                    <span className='dot'></span>
                </div> 
                <div className='bubble'>
                    <span className='dot'></span>
                </div> 
                <div className='bubble'>
                    <span className='dot'></span>
                </div> 
                <div className='bubble'>
                    <span className='dot'></span>
                </div>
            </div>
            
            </div>
            {showModal && !hasVisited ? 
                <div className='modal-popup'>
                    <button onClick={handleClose} className='close-btn'>X</button>
                    <NewsletterSignup />

                </div> 
                : <div></div>}
        </div>
 
    )
}

export default LandingPage;