import styled from 'styled-components';

export const ToDoListContainer = styled.div`

`;

export const ToDoInput = styled.input`
    height: 35px;
    padding: 10px;
    font-size: 16px;
`;

export const ToDoBtn = styled.button`
    margin-left: 15px;
    height: 60px;
    padding: 10px;
    width: 60px;
    border: none;
    cursor: pointer;
    background-color: #00beef;
    border-radius: 5px;
    color: white;
    font-size: 20px;

    &:hover {
        background-color: #00aad6;
    }
`;

export const ToDoListItems = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
`;

export const RemoveBtn = styled.button`
    font-size: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #ef3100;
    color: white;
    width: 40px;
    margin: 0 auto;
    height: 40px;

    &:hover {
        background-color: #d62c00;
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

export const ListItem = styled.li`
    width: 75%;
    border-bottom: 1px solid gray;
    margin: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 5px;
    transform: translateX(-5%);
    text-wrap: break-word;

    @media (max-width: 768px) {
        min-width: 95%;
        font-size: 20px;
    }
`;