import './newsletter-signup.styles.scss';

// use the render prop and your custom form
const NewsletterSignup = () => {
  return (
    <div>
        <iframe title='newsletter signup form' width="540" height="305" src="https://0c403b3b.sibforms.com/serve/MUIFAKZQfkeSE1oQEiupSu3qtKS2PXPTort--ebvD-k-EB7Pc6Fum4WzS9iChlvo_SjObnIzPw73aJdwz24c1NPASdYVMoJ-G7ASFhnq-Soa3NHOtqLp5R4-ITN5AvR71ChM8dy-2hSxRPg2Q47YSLuEFsXrLnmUbLqub65v9NcIc1DqZC-YzBBl7utWzVWcHjsK5mNMyCWJn9j3" frameborder="0" scrolling="auto" allowfullscreen className='newsletter-signup'></iframe>
    </div>
  )
}

export default NewsletterSignup;

