import picture from '../../../assets/profile-pic.jpg';
import './card.styles.scss';
import resume from '../../../assets/Daniel_Wernz_Resume.docx';

const Card = () => {
    return (
        <div>
            <figure className='card'>
                <img src={picture} alt='profile_pic' className='card_image' />
                <figcaption className='card_body'>
                    <div>
                        <h2 className='card_category'>Daniel Wernz</h2>
                        <p className='card_author'>Software Developer</p>
                    </div>
                    <a href={resume} download="Daniel_Wernz_Resume.docx" className='card_button'>Download Resume</a>
                </figcaption>
            </figure>
        </div>
    )
}

export default Card;