const experiences = [
    {
        id: 0,
        title: 'FIRST LEGO League Coach',
        location: 'Shark Bytes Team #60097',
        startDate: 'May 2023',
        endDate: 'Present',
        tasks: [
            `Oversees a team of up to ten students in grades 4 to 6, helping them develop STEM-related skills.`,
            `Mentor students in software development using scratch and Python programming using LEGO's Spike software to program a robot to complete 15 missions in 150 seconds.`,
            `Educate students and parents on the fundamentals of programming design and logic and the importance of documentation that can be applied to any programming language.`,
            `Assist students with LeoCAD, a LEGO CAD software to keep record of design builds for the robot.`,
            `Oversee the design and development of the team website.`
        ]
    },
    {
        id: 1,
        title: 'Divemaster Candidate',
        location: 'Dayo Scuba',
        startDate: 'January 2022',
        endDate: 'Present',
        tasks: [
            'Assist Dive Instructor(s) with certification classes, by supervising groups during dives in open water conditions, ensuring the safety of scuba divers.',
            'Aid in merchandise at the dive shop, helping to sell over $5,000 in dive gear during the summer months.',
            'Fill, inspect, and analyze several hundred scuba cylinders with varying oxygen, nitrogen, and helium levels.',
        ]
    },
    {
        id: 2,
        title: 'Office Assistant',
        location: 'Itani Medical',
        startDate: 'January 2023',
        endDate: 'April 2023',
        tasks: [
            'Called patients to collect over $6000 in overdue payments, maintain accurate records of payment transactions, and resolved billing and payment-related issues with patients and insurance providers.',
            'Provided patients with detailed explanations of their billing statements, while maintaining confidentiality of patient information and adhering to HIPAA regulations.',
            'Created and maintained a database with over 400 patients as a call log for patients when contacted about billing and generating reports to the Office Manager.',
            'Troubleshot and fixed computer hardware and software issues.'
        ]
    },
    {
        id: 3,
        title: 'Executive Recruiter',
        location: 'Lee Hecht Harrison',
        startDate: 'March 2022',
        endDate: 'November 2022',
        tasks: [
            'Responsible for full-cycle recruiting, including sourcing, interviewing, qualifying, and placement of a full range of IT and non-IT candidates for contract, contract-to-hire, and direct-hire positions.',
            'Collaborated with hiring managers to understand their hiring needs and goals.',
            'Leveraged multiple sourcing strategies and channels to provide high-quality candidates for open positions, including external networks, job boards, and internal referrals.',
            'Screened resumes, interviewed qualified candidates, evaluated skill level, managed offer profess, reference checks, and made salary recommendations.'
        ]
    },
    {
        id: 4,
        title: 'Junior Apprentice',
        location: 'The Greenbrier',
        startDate: 'November 2017',
        endDate: 'September 2019',
        tasks: [
            'Responsible as Junior Apprentice to assist in mentoring line cooks through 20 food and beverage outlets and assisting leadership with disciplinary actions if necessary.',
            'Brought forth new ideas identifying different key elements of menu creation with leadership in the Main Dining Room, serving 250 people a night on average.'
        ]
    },

    // {
    //     title: '',
    //     location: '',
    //     startDate: '',
    //     endDate: '',
    //     tasks: [

    //     ]
    // },
]

export default experiences;