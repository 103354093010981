import React from 'react';
import './search-box.styles.scss';

const SearchBox = ({ searchfield, searchChange }) => {
  return (
    <div className='search-box'>
      <input
        className='search-input'
        type='search'
        placeholder='search companies'
        onChange={searchChange}
      />
    </div>
  );
}

export default SearchBox;