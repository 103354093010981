const projects = [
    // Daniel's MAUI Test App
    {
        id: 0,
        type: 'app',
        title: `Daniel's MAUI Test App`,
        text: 'I developed a comprehensive app using .NET MAUI to gain hands-on experience in app development. The app consists of various mini applications that showcase different skills and functionalities. Starting with a simple Counter app, I progressively built more advanced features like Sales, Tip, and Hours calculators, a Math Quiz, and a Dice app for gaming. Additionally, I created Shopping List and Price apps to streamline shopping experiences. Currently, the app is available exclusively for Android devices through the Google Play Store',
        liveLink: 'https://play.google.com/store/apps/details?id=com.danielwernz.testapp',
        codeLink: 'https://github.com/dwernz/MAUI_TestApp',
        imageUrl: 'https://i.ibb.co/4T7wTy3/test-app.jpg'
    },
    // Robofriends
    {
        id: 1,
        type: 'website',
        title: 'RoboFriends',
        text: `The 'Robofriends' project serves as an introduction to React, showcasing the implementation of array functions to enable efficient filtering of robot profiles based on their names. With this application, users can easily navigate through a directory of profiles and perform quick searches by name. By leveraging the power of React and array functions, the project demonstrates the ability to create dynamic and responsive user interfaces for effective data manipulation`,
        liveLink: 'https://dwernz.github.io/robofriends/',
        codeLink: 'https://github.com/dwernz/robofriends',
        imageUrl: 'https://i.ibb.co/xmNHQny/robofriends.png'
    },
    // Face Recognition
    {
        id: 2,
        title: 'Face Recognition',
        text: `Utilizing the power of React, Node.js, JavaScript, and Clarifai's Face Detect API, this cutting-edge webpage empowers users to detect faces within images by simply providing the image URL. Serving as the culminating project of the comprehensive Zero to Mastery Complete Web Development course, this application showcases the integration of various technologies to deliver a seamless and intuitive user experience. Deployed with Render.com, this project demonstrates the practical implementation of industry-standard tools and methodologies. Join me in exploring the captivating world of face detection and witness firsthand the transformative capabilities of modern web development.`,
        liveLink: 'https://facerecognitionbrain-bl4h.onrender.com/',
        codeLink: 'https://github.com/dwernz/facerecognitionbrain',
        imageUrl: 'https://i.ibb.co/DwQK1QF/face-recognition.png',
        type: 'website'
    },
    // Background Generator
    {
        id: 3,
        title: 'Background Generator',
        text: `Harnessing the power of HTML, CSS, JavaScript, and the DOM, this captivating webpage offers a unique interactive experience where users can dynamically generate a stunning linear gradient background by selecting two colors of their choice. Through seamless integration of front-end technologies, this project showcases the versatility and creativity that can be achieved with web development. Immerse yourself in the world of vibrant gradients and unleash your artistic expression with this captivating web application.`,
        liveLink: 'https://dwernz.github.io/ZeroToMastery/DOM%20Manipulation//BackgroundGenerator/index.html',
        codeLink: 'https://github.com/dwernz/ZeroToMastery/tree/master/DOM%20Manipulation/BackgroundGenerator',
        imageUrl: 'https://i.ibb.co/zSBp0jP/background-generator.png',
        type: 'website'
    },
    // Random Quote Generator
    {
        id: 4,
        title: 'Random Quote Generator',
        text: `Experience a dose of inspiration with a collection of thought-provoking quotes sourced from an API. In the event of any connectivity issues, the application seamlessly switches to a local file as a backup, ensuring a consistent supply of insightful quotes. Each time the page is loaded or refreshed, a random quote is dynamically displayed, offering a fresh perspective or a moment of reflection. Embark on a journey of inspiration as you discover a diverse range of quotes that resonate with you on a personal level.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/quote-generator/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/quote-generator',
        imageUrl: 'https://i.ibb.co/kXjTxLq/quote-generator.png',
        type: 'website'
    },
    // Infinite Scroll 
    {
        id: 5,
        title: 'Infinite Scroll',
        text: `Create an immersive browsing experience with an array of stunning photos generated using Unsplash's API. As users scroll through the page, additional photos are dynamically loaded to ensure a seamless and uninterrupted viewing experience. By detecting when the user approaches the bottom of the page, more photos are fetched and seamlessly added to the existing collection. This continuous loading feature allows users to discover an endless array of captivating visuals without the need for manual page refresh. Immerse yourself in a world of captivating photography as you explore the dynamically expanding collection.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/infinite-scroll',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/infinite-scroll',
        imageUrl: 'https://i.ibb.co/JzcN1gX/infinite-scroll.png',
        type: 'website'
    },
    // Picture in Picture
    {
        id: 6,
        title: 'Picture in Picture',
        text: `Enhance your multimedia experience with a custom video player that enables Picture-in-Picture functionality. Through the integration of video player elements, users can choose from various sources to create their Picture-in-Picture stream. It's important to note that this feature is not connected to a server and does not broadcast any streams. Instead, users can leverage this functionality for personal purposes such as using their webcam as a mirror or enjoying videos while multitasking with other applications. Simply select the desired content to stream and hit the start button to display the Picture-in-Picture video on your screen. Elevate your viewing experience and make the most out of your multimedia content.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/picture-in-picture/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/picture-in-picture',
        imageUrl: 'https://i.ibb.co/cC4fQhT/picture-in-picture.png',
        type: 'website'
    },
    // Joke Teller
    {
        id: 7,
        title: 'Joke Teller',
        text: `Create a delightful and interactive experience with the integration of two APIs. By leveraging these APIs, you can fetch a joke from a reliable source and bring it to life using text-to-speech technology. Users can enjoy a good laugh as the joke is transformed into audio format, adding an engaging and entertaining element to your application. It's important to note that due to the latency of the text-to-speech API, there might be a slight delay in the audio playback. Additionally, please exercise caution as the jokes may include mature content that has not been filtered. Get ready for some laughter-filled moments and an enjoyable user experience!`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/joke-teller/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/joke-teller',
        imageUrl: 'https://i.ibb.co/h7L6SwF/joke.png',
        type: 'website'
    },
    // Light/Dark Mode
    {
        id: 8,
        title: 'Light/Dark Mode',
        text: `Enhance the user experience with a customizable visual theme using the power of the DOM. By implementing light mode and dark mode styling options, you can provide users with the flexibility to choose their preferred color scheme. Through the utilization of local storage, their selection is saved, ensuring a consistent experience across visits without the need to repeatedly set their preference. Whether it's a soothing light theme or a sleek dark mode, this dynamic styling approach adds a touch of personalization and enhances the overall aesthetics of your website.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/light-dark-mode/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/light-dark-mode',
        imageUrl: 'https://i.ibb.co/zGYhK61/light-dark.png',
        type: 'website'
    },
    // Animation
    {
        id: 9,
        title: 'Animation',
        text: `Elevate the visual appeal of your webpage with stunning animation effects using a Tailwind Template integrated with the Animate On Scroll (AOS) Library. By leveraging this powerful combination of tools, you can effortlessly enhance the user experience by adding captivating animations to elements as they scroll into view. From subtle fades to dynamic transitions, this implementation adds a touch of interactivity and sophistication to your website, leaving a lasting impression on your visitors.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/animated/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/animated',
        imageUrl: 'https://i.ibb.co/VCvMVyt/animation.png',
        type: 'website'
    },
    // Navigation Nation
    {
        id: 10,
        title: 'Navigation Nation',
        text: `Experience a sleek and intuitive menu design that seamlessly integrates into your webpage using JavaScript and CSS. This dynamic menu overlays your content, providing a convenient and stylish navigation experience. Easily exit the menu or select desired options with just a few clicks, enhancing user interaction and overall website functionality.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/navigation-nation/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/navigation-nation',
        imageUrl: 'https://i.ibb.co/YRx3gZ5/nav.png',
        type: 'website'
    },
    // Music Player
    {
        id: 11,
        title: 'Music Player',
        text: `Immerse yourself in a visually captivating music player application meticulously crafted using HTML, CSS, and JavaScript. This project brings together an engaging user interface, carefully curated music from yoyosound.com, and stunning visuals sourced from unsplash.com. Indulge in an immersive audio experience while enjoying the seamless blending of art and technology.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/music-player/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/music-player',
        imageUrl: 'https://i.ibb.co/yPNh4b0/music.png',
        type: 'website'
    },
    // Custom Countdown
    {
        id: 12,
        title: 'Custom Countdown',
        text: `Experience the power of anticipation with a sleek countdown timer designed to track your selected date. This project offers a seamless user experience as it utilizes local storage to preserve your chosen title and date, ensuring that your countdown remains intact even when visiting or refreshing the page. Enjoy the convenience of always having your countdown at your fingertips, as you eagerly await the special moment.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/custom-countdown/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/custom-countdown',
        imageUrl: 'https://i.ibb.co/P4LmXbB/countdown.png',
        type: 'website'
    },
    // Bookmark Keeper
    {
        id: 13,
        title: 'Bookmark Keeper',
        text: `Step into the world of bookmark management with a visually captivating twist. This project introduces a bookmarking feature reminiscent of the one found in popular web browsers, but with a delightful and engaging user interface. Seamlessly organize and access your favorite websites with ease, enjoying a visually appealing experience that enhances your bookmarking journey. Say goodbye to cluttered bookmark lists and welcome a visually appealing solution that elevates your browsing experience to new heights.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/book-keeper/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/book-keeper',
        imageUrl: 'https://i.ibb.co/KWjhQSJ/bookmarks.png',
        type: 'website'
    },
    // Custom Video Player
    {
        id: 14,
        title: 'Video Player',
        text: `Embark on a thrilling journey through the realm of video player functionality with this remarkable project. Discover a meticulously crafted collection of essential features that have become a staple in modern video players. Engage in seamless control over your viewing experience as you navigate through volume adjustments, fullscreen mode, playback speed modifications, and effortless pause/play functionality. Unlock the power to effortlessly skip to specific parts of the video, providing you with unparalleled convenience and precision. Immerse yourself in this immersive project and witness firsthand how it enhances your video watching pleasure.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/video-player/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/video-player',
        imageUrl: 'https://i.ibb.co/FYQtvWB/video.png',
        type: 'website'
    },
    // Form Validator
    {
        id: 15,
        title: 'Form Validator',
        text: `Immerse yourself in a dynamic project that showcases the power of HTML properties and JavaScript functions through an advanced form validator. Experience the seamless validation of formats, matching passwords, and required input, all brought to life in a captivating web application. Explore the depths of efficient form handling and witness  how this project enhances the user experience by ensuring accurate and complete data submissions. Unleash the potential of your web forms and elevate their functionality with this captivating demonstration.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/form-validator/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/form-validator',
        imageUrl: 'https://i.ibb.co/gjc9K4P/form.png',
        type: 'website'
    },
    // Rock Paper Scissors Lizard Spock Game
    {
        id: 16,
        title: 'Rock Paper Scissors Lizard Spock Game',
        text: `Experience the excitement of Rock Paper Scissors Lizard Spock, a captivating twist on the classic game. Challenge the computer in an epic battle of wits and strategy. Test your decision-making skills as you choose between rock, paper, scissors, lizard, or Spock. Will you emerge victorious? Get ready to engage in thrilling matches and prove your mastery in this modern rendition of a timeless favorite.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/spock-rock-game/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/spock-rock-game',
        imageUrl: 'https://i.ibb.co/nQQPCmF/spock.png',
        type: 'website'
    },
    // NASA APOD
    {
        id: 17,
        title: 'NASA APOD',
        text: `Using NASA's API, and front-end technologies to make a blog style website for NASA's Astronomy Picture of the Day, that can also locally store user favorites.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/nasa-apod/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/nasa-apod',
        imageUrl: 'https://i.ibb.co/5GvC6Jk/nasa.png',
        type: 'website'
    },
    // Math Sprint Game
    {
        id: 18,
        title: 'Math Sprint Game',
        text: `Developed an engaging and interactive math sprint game using front-end technologies like HTML, CSS, and JavaScript. Test your math skills and race against the clock as you solve math problems. The game saves and displays the best scores locally, allowing you to track your progress and compete with yourself. Get ready for an exciting challenge and unleash your mathematical prowess in this immersive gaming experience!`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/math-sprint-game/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/math-sprint-game',
        imageUrl: 'https://i.ibb.co/ygJHJ3p/math.png',
        type: 'website'
    },
    // Kanban Board
    {
        id: 19,
        title: 'Drag and Drop Kanban Board',
        text: `Enhance your project and task management experience with a powerful Kanban board solution. Streamline your workflow, track progress, and collaborate effectively with this intuitive and visually appealing project management tool. Stay organized, prioritize tasks, and boost productivity with our user-friendly Kanban board, designed to optimize your project management process.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/drag-and-drop/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/drag-and-drop',
        imageUrl: 'https://i.ibb.co/nRrmhSb/kanban.png',
        type: 'website'
    },
    // Calculator
    {
        id: 20,
        title: 'Calculator',
        text: `A web development project featuring a stylish calculator built using HTML, CSS, and JavaScript. This sleek and user-friendly calculator offers a responsive design, ensuring seamless functionality across devices. With its clean interface, intuitive features, and efficient JavaScript code, it provides a reliable tool for basic calculations.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/calculator/',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/calculator',
        imageUrl: 'https://i.ibb.co/RjzvB3r/calc.png',
        type: 'website'
    },
    // Splash Page
    {
        id: 21,
        title: 'Splash Page',
        text: `A modern looking webpage brought to life from a Figma design. The standout feature is the button-driven background toggling, allowing users to switch between different themes for a personalized experience.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/splash-page',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/splash-page',
        imageUrl: 'https://i.ibb.co/BcC8ry1/splash.png',
        type: 'website'
    },
    // Paint Clone
    {
        id: 22,
        title: 'Paint Clone',
        text: `Experience a modern take on a beloved classic - Paint. Explore a simplified version that brings nostalgic memories to life, showcasing expertise in front-end development. Unleash your creativity and let your imagination run wild with this interactive project!`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/paint-clone',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/paint-clone',
        imageUrl: 'https://i.ibb.co/hcWHRT4/paint.png',
        type: 'website'
    },
    // Pong
    {
        id: 23,
        title: 'Pong',
        text: `Immerse yourself in a nostalgic gaming experience with this classic recreation of Pong. Witness the iconic game come to life on your screen, created entirely using the power of Canvas properties. Experience the thrill of the virtual ping-pong battle as you control the paddles, striving to outwit your opponent and secure victory. With fluid animations, responsive controls, and an authentic retro design, this rendition of Pong will transport you back to the golden age of arcade gaming. Rediscover the simplicity and addictive gameplay that captivated generations, and relive the magic of this timeless masterpiece.`,
        liveLink: 'https://dwernz.github.io/ZTM_Portfolio/project/pong',
        codeLink: 'https://github.com/dwernz/ZTM_Portfolio/tree/master/project/pong',
        imageUrl: 'https://i.ibb.co/yS0ghHL/pong.png',
        type: 'website'
    },
    // Crwn Clothing
    {
        id: 24,
        title: "Crwn Clothing",
        text: `Disclaimer: This is only a site to showcase knowledge, placing orders will not go through, and there will be no transactions. Using the power of React, Firebase, and other frontend technologies, this webpage is sample e-commerce site. With features to sign in, add items to cart, and checkout.`,
        liveLink: 'https://marvelous-babka-9311ac.netlify.app/shop',
        codeLink: 'https://github.com/dwernz/crwn-clothing',
        imageUrl: 'https://i.ibb.co/C24NDxC/2023-10-05.png',
        type: 'website'
    },
    // {
    //     id: 25,
    //     title: '',
    //     text: ``,
    //     liveLink: '',
    //     codeLink: '',
    //     imageUrl: '',
    //     type: 'website'
    // },
    // {
    //     id: 26,
    //     title: '',
    //     text: ``,
    //     liveLink: '',
    //     codeLink: '',
    //     imageUrl: '',
    //     type: 'website'
    // },
    // {
    //     id: 27,
    //     title: '',
    //     text: ``,
    //     liveLink: '',
    //     codeLink: '',
    //     imageUrl: '',
    //     type: 'website'
    // },
];

export default projects;