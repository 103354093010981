import './card-skeleton.styles.scss';

const CardSkeleton = () => {
    return (
        <div className='skeleton-container'>
            <div className='card-skeleton'>
                <div className='header'>
                    <div className='img'></div>
                    <div className='details'>
                        <span className='name'></span>
                        <span className='about'></span>
                    </div>
                </div>
                <div className='description'>
                    <div className='line line-1'></div>
                    <div className='line line-2'></div>
                    <div className='line line-3'></div>
                </div>
                <div className='btns'>
                    <div className='skeleton-btn btn-1'></div>
                    <div className='skeleton-btn btn-2'></div>
                </div>
            </div>
        </div>
    )
}

export default CardSkeleton;