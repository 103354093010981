import './hover-cards.styles.scss';

const HoverCards = () => {
    return (
        <div className='hover-cards-gallery'>
            <div className='hover-card'>Card 1</div>
            <div className='hover-card'>Card 2</div>
            <div className='hover-card'>Card 3</div>
            <div className='hover-card'>Card 4</div>
        </div>
    )
}

export default HoverCards;