import { Background, Button, ButtonContainer } from './davidmai-button-2.style';

const DavidmaiButton2 = () => {
    return (
        <ButtonContainer>
            <Background>
                <Button href='https://learning.atheros.ai'>
                    Learn
                </Button>
            </Background>
            <p>Davidm_ai Button 2</p>
        </ButtonContainer>
    )
}

export default DavidmaiButton2;