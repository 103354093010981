import './button.styles.scss';

const Button = ({buttonType, link}) => {

    let buttonText = '';
    let isLinkBtn = false;

    switch (buttonType) {
        case 'live-link':
            buttonText = 'Live Link';
            isLinkBtn = true;
            break;
        case 'code-link':
            buttonText = 'Code Link';
            isLinkBtn = true;
            break;
        case 'app':
            buttonText = 'Get it on the Google Play';
            break;
        case 'view-more':
            buttonText = 'View More Projects';
            break;
        default:
            break;
    }
    
    return (
        <button className={`btn ${buttonType}`}> 
            <a href={link} target={isLinkBtn ? '_blank' : ''} rel="noreferrer">{buttonText}</a>
        </button>
    );
}

export default Button;