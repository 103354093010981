import { Button } from './bootstrap-button.style';

const BootstrapButton = ({ text, theme, size }) => {

    let bgColor = "transparent";
    let hover = "";
    let textColor = "#000000";
    let borderColor = "transparent";
    let hoverText = "";

    let colors = {
        primary: {
            main: "#0d6efd",
            hover: "#0b5ed7"
        },
        secondary: {
            main: "#6c757d",
            hover: "#5c636a"
        },
        success: {
            main: "#198754",
            hover: "#157347"
        },
        danger: {
            main: "#dc3545",
            hover: "#bb2d3b"
        },
        warning: {
            main: "#ffc107",
            hover: "#ffca2c"
        },
        info: {
            main: "#0dcaf0",
            hover: "31d2f2"
        },
        light: {
            main: "#f8f9fa",
            hover: "#d3d4d5"
        },
        dark: {
            main: "#212529",
            hover: "#424649"
        },
        text: {
            light: "#ffffff",
            dark: "#000000",
        }
    }

    switch (theme) {
        case "primary":
            bgColor = colors.primary.main;
            hover = colors.primary.hover;
            textColor = colors.text.light;
            break;
        case "secondary":
            bgColor = colors.secondary.main;
            hover = colors.secondary.hover;
            textColor = colors.text.light;
            break;
        case "success":
            bgColor = colors.success.main;
            hover = colors.success.hover;
            textColor = colors.text.light;
            break;
        case "danger":
            bgColor = colors.danger.main;
            hover = colors.danger.hover;
            textColor = colors.text.light;
            break;
        case "warning":
            bgColor = colors.warning.main;
            hover = colors.warning.hover;
            break;
        case "info":
            bgColor = colors.info.main;
            hover = colors.info.hover;
            break;
        case "light":
            bgColor = colors.light.main;
            hover = colors.light.hover;
            break;
        case "dark":
            bgColor = colors.dark.main;
            hover = colors.dark.hover;
            textColor = colors.text.light;
            break;
        case "outline-primary":
            borderColor = colors.primary.main;
            textColor = colors.primary.main;
            hover = colors.primary.main;
            hoverText = colors.text.light;
            break;
        case "outline-secondary":
            borderColor = colors.secondary.main;
            textColor = colors.secondary.main;
            hover = colors.secondary.main;
            hoverText = colors.text.light;
            break;
        case "outline-success":
            borderColor = colors.success.main;
            textColor = colors.success.main;
            hover = colors.success.main;
            hoverText = colors.text.light;
            break;
        case "outline-danger":
            borderColor = colors.danger.main;
            textColor = colors.danger.main;
            hover = colors.danger.main;
            hoverText = colors.text.light;
            break;
        case "outline-warning":
            borderColor = colors.warning.main;
            textColor = colors.warning.main;
            hover = colors.warning.main;
            hoverText = colors.text.dark;
            break;
        case "outline-info":
            borderColor = colors.info.main;
            textColor = colors.info.main;
            hover = colors.info.main;
            hoverText = colors.text.dark;
            break;
        case "outline-light":
            borderColor = colors.light.main;
            textColor = colors.light.main;
            hover = colors.light.main;
            hoverText = colors.text.dark;
            break;
        case "outline-dark":
            borderColor = colors.dark.main;
            textColor = colors.dark.main;
            hover = colors.dark.main;
            hoverText = colors.text.light;
            break;
        default:
            break;
    }

    return (
        <Button bgColor={bgColor} hover={hover} textColor={textColor} borderColor={borderColor} hoverText={hoverText} className={size}>
            {text}
        </Button>
    );
}

export default BootstrapButton;
