import styled from 'styled-components';

export const Button = styled.button`
    padding: .375rem 0.75rem ;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${props => props.bgColor};
    color: ${props => props.textColor};
    border: 1px solid ${props => props.borderColor};

    &:hover {
        background-color: ${props => props.hover};
        color: ${props => props.hoverText};
    }

    &.small {
        font-size: .875rem;
        padding: 0.25rem 0.5rem;
    }

    &.large {
        font-size: 1.25rem;
        padding: 0.5rem 1.25rem;
    }

    &.disabled {
        filter: brightness(1.2);
    }
`;