import skills from "../../assets/data-arrays/skills";
import './skills-list.styles.scss';

const SkillsList = () => {
    return (
        <div className='my-skills-container container'>
            <h3 className='skills-title'>My Skills</h3>
            {skills.map( (skill, index) => (
                <div key={index} className='skill-box'>
                    <h3>{skill.name}</h3>
                </div>
            ))}
        </div>
    )
}

export default SkillsList;