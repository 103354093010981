import './hover-button.styles.scss';

const HoverButton = () => {
    return (
        <div className='hover-button-container'>
            <span className='background'>
                <a href='https://learning.atheros.ai' className='button'>
                    <svg>
                        <rect 
                            x='0' y='0'
                            fill='none'
                            width='100%'
                            height='100%'
                        />
                    </svg>
                    Learn
                </a>
            </span>
        </div>
    )
}

export default HoverButton;