import { Background, Button, ButtonContainer, Rect, Svg } from './davidmai-button-1.style';

const DavidmaiButton1 = () => {
    return (
        <ButtonContainer>
            <Background>
                <Button href='https://learning.atheros.ai'>
                    <Svg>
                        <Rect 
                            x='0' y='0' fill='none' width='100%' height='100%'
                        />
                    </Svg>
                    Learn
                </Button>
            </Background>
            <p>Davidm_ai Button 1</p>
        </ButtonContainer>
    )
}

export default DavidmaiButton1;