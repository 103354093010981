import { useState, useRef } from 'react';

import { ToDoListContainer, ToDoInput, ToDoBtn, ToDoListItems, RemoveBtn, ListItem } from './todo-list.styles';

const ToDoList = () => {
    const [toDoListItems, setToDoListItems] = useState([]);
    const inputRef = useRef();

    const addToDoListItem = () => {
        if (inputRef.current.value !== '') {
            setToDoListItems([
                ...toDoListItems,
                inputRef.current.value,
            ]);
            
            inputRef.current.value = '';
        }
    };

    const removeToDoListItem = (index) => {
        setToDoListItems(toDoListItems.filter((toDoListItem, i) => i !== index));
    };

    return (
        <ToDoListContainer>
            <ToDoInput
                type="text"
                placeholder="Add a new to-do list item..."
                ref={inputRef}
            />
            <ToDoBtn onClick={addToDoListItem}>Add</ToDoBtn>
            <ToDoListItems>
                {toDoListItems.map((toDoListItem, index) => (
                <ListItem key={index}>
                    {toDoListItem}
                    <RemoveBtn onClick={() => removeToDoListItem(index)}>X</RemoveBtn>
                </ListItem>
                ))}
            </ToDoListItems>
        </ToDoListContainer>
    )
}

export default ToDoList;
