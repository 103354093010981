import {
    SoundwaveContainer,
    Sound,
    Wave
} from './soundwave.styles';

const Soundwave = () => {
    return (
        <SoundwaveContainer>
            <Sound>
                <Wave></Wave>
                <Wave></Wave>
                <Wave></Wave>
                <Wave></Wave>
                <Wave></Wave>
            </Sound>
        </SoundwaveContainer>
    )
}

export default Soundwave;