import {
    NavbarMuhilanContainer,
    List,
    Link,

} from './navbar-muhilan.styles';

const NavbarMuhilan = () => {
    return (
        <NavbarMuhilanContainer>
            <List>
                <li>
                    <Link>Home</Link>
                </li>
                <li>
                    <Link>Blogs</Link>
                </li>
                <li>
                    <Link>Services</Link>
                </li>
                <li>
                    <Link>Contact</Link>
                </li>
                <li>
                    <Link>About</Link>
                </li>
            </List>
        </NavbarMuhilanContainer>
    )
}

export default NavbarMuhilan;