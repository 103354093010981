import React, { useState } from "react";
import emailjs from 'emailjs-com';

import './contact-form.styles.scss';
import SectionHeader from "../section-header/section-header.components";

emailjs.init('jMydJDASxfp0-VVYf');

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
    await emailjs.send(
      'service_8ax3bf2',
      'template_yc4lizk',
      {
        from_name: name,
        reply_to: email,
        message,
        to_name: 'Daniel'
      }
    );

    // Reset the form
    setName('');
    setEmail('');
    setMessage('');
    alert('Email sent successfully!');
  } catch (error) {
    console.error('Error sending email:', error);
    alert('Error sending email. Please try again.');
  }
  };

  return (
    <div className='contact-container'>
      
      <SectionHeader 
        title='Contact Me 📞'
        text='Drop me a message, and I will get back to you as soon as possible.'
      />

        <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className='input-field'
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='input-field'
            />
            <textarea
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className='input-field'
            />
            <button type="submit">Submit</button>
        </form>
    </div>
  );
};

export default ContactForm;