import styled from 'styled-components';

export const ButtonContainer = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Mulish;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Background = styled.span`
    background-color: #2c2c2c;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 20vw;
    border-radius: 8px;
`;

export const Button = styled.a`
    border: 0px solid;
    outline: 2px solid;
    color: white;
    outline-color: white;
    outline-offset: 0px;
    text-shadow: none;
    cursor: pointer;
    font-size: 1rem;
    line-height: 2.5rem;
    max-width: 160px;
    width: 100%;
    letter-spacing: 0.3rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    transition: all 550ms cubic-bezier(
        .36,.63,.5,.73
    );

    &:hover {
        border: 2px solid #ff9950;
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 15px;
        color: #ff9950;
    }
`;