import { Fragment, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';

import logo from '../../assets/logo.png';
import burgerMenu from '../../assets/burger-menu.png';
import closeMenu from '../../assets/close-menu.png';
import './navigation.styles.scss';

const Navigation = () => {  
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Fragment>
      <div className='navigation'>
        <Link className='logo-container' to='/'>
          <img src={logo} className='logo' alt='Logo' />
        </Link>
        <div className={`nav-links-container ${isMenuOpen ? 'active' : ''}`}>
          <button className='menu-toggle' onClick={toggleMenu}>
            <img src={burgerMenu} className='burger-menu' alt='burgerMenu' />
          </button>
          <div className='nav-links'>
            <Link className='nav-link' to='/'>
              HOME
            </Link>
            <Link className='nav-link' to='/about'>
              ABOUT
            </Link>
            <Link className='nav-link' to='/apps'>
              APPS
            </Link>
            <Link className='nav-link' to='/projects'>
              PROJECTS
            </Link>
            <Link className='nav-link' to='/shop'>
              AFFILIATES
            </Link>
            <Link className='nav-link' to='/contact'>
              CONTACT
            </Link>
          </div>
          <div className={`nav-links-menu ${isMenuOpen ? '' : 'hidden'}`} >
            <img src={closeMenu} className='close-menu' onClick={toggleMenu} alt='toggleMenu' />
            <Link className='nav-link-menu nav-link-1' to='/' onClick={toggleMenu}>
              HOME
            </Link>
            <Link className='nav-link-menu nav-link-2' to='/about' onClick={toggleMenu}>
              ABOUT
            </Link>
            <Link className='nav-link-menu nav-link-3' to='/apps' onClick={toggleMenu}>
              APPS
            </Link>
            <Link className='nav-link-menu nav-link-4' to='/projects' onClick={toggleMenu}>
              PROJECTS
            </Link>
            <Link className='nav-link-menu nav-link-5' to='/shop' onClick={toggleMenu}>
              AFFILIATES
            </Link>
            <Link className='nav-link-menu nav-link-6' to='/contact' onClick={toggleMenu}>
              CONTACT
            </Link>
          </div>
        </div>
      </div>
      <Outlet />
    </Fragment>
  )
}

export default Navigation;