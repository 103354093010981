import './back-to-top.styles';

import React, { useEffect, useState } from 'react';
import { ScrollToTopBtn } from './back-to-top.styles';

const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        }
        else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ScrollToTopBtn
      isVisible={isVisible}
      onClick={scrollToTop}
    >
      Back to Top
    </ScrollToTopBtn>
  );
};

export default BackToTop;