import styled from 'styled-components';

export const AppsContainer = styled.div`
    text-align: center;
    margin: 50px 20px;
    font-size: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1268px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;


export const AppContainer = styled.div`
    margin: 35px 15px;
`;

export const AppDescription = styled.p`
    text-align: justify;
    padding: 0 15px;
    font-size: 20px;
    
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;