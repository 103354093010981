import React, { useState, useRef } from "react";

import { BreakInput, CalculateBtn, CheckBox, DurationCalculatorContainer, LabelText, LineContainer, TimeInput } from './duration-calculator.styles';

const DurationCalculator = () => {
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [duration, setDuration] = useState("");
    const [durationHours, setDurationHours] = useState('');
    const [durationMinutes, setDurationMinutes] = useState('');

    const [breakChecked, setBreakChecked] = useState(false);
    const [breakTime, setBreakTime] = useState(0);

    const toggleBreakChecked = () => {
        setBreakChecked(!breakChecked);
    };

    console.log(startTime);
    console.log(endTime);

    const startTimeRef = useRef();
    const endTimeRef = useRef();

    const calculateDuration = () => {
        const startTimeInMS = Date.parse(startTimeRef.current.value);
        const endTimeInMS = Date.parse(endTimeRef.current.value);

        const breakTimeInMilliseconds = breakTime * 60 * 1000;

        const durationInMS = endTimeInMS - startTimeInMS - breakTimeInMilliseconds;
        const durationInHours = durationInMS / (1000 * 60 * 60);
        const durationInMinutes = durationInMS % (1000 * 60 * 60) / (1000 * 60);


        setDuration(durationInHours.toFixed(2));
        setDurationHours(Math.floor(durationInHours));
        setDurationMinutes(Math.floor(durationInMinutes));
    };

    return (
        <DurationCalculatorContainer>
            <LineContainer>
                <LabelText>Start Time:</LabelText>
                <TimeInput
                    type="datetime-local"
                    ref={startTimeRef}
                    onChange={(e) => setStartTime(e.target.value)}
                />
            </LineContainer>
            
            <LineContainer>
                <LabelText>End Time:</LabelText>
                <TimeInput
                    type="datetime-local"
                    ref={endTimeRef}
                    onChange={(e) => setEndTime(e.target.value)}
                />
            </LineContainer>

            <LineContainer>
                <CheckBox
                    type="checkbox"
                    checked={breakChecked}
                    onChange={toggleBreakChecked}
                />
                <span>Break?</span>
                {breakChecked && (
                    <div>
                        <BreakInput
                            type="number"
                            value={breakTime}
                            onChange={(e) => setBreakTime(e.target.value)}
                        />
                        <LabelText>minutes</LabelText>
                    </div>
                )}
            </LineContainer>
            
            <CalculateBtn onClick={calculateDuration}>Calculate</CalculateBtn>
            <p>Duration: {duration} hours</p>
            <p>{durationHours} Hours and {durationMinutes} Minutes</p>
        </DurationCalculatorContainer>
    );
};

export default DurationCalculator;