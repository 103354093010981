const categories = [
    {
        id: 0,
        title: 'Amway',
        subtitle: 'Earn AmPerk Points with each purchase',
        imageUrl: 'https://i.ibb.co/8DmZ3vy/amway.jpg',
        link: 'https://www.amway.com/en_US/myshop/DanielWernz',
        type: ['food & drink', 'health & beauty', 'family', 'gift & flowers', 'home & living', 'sports & fitness']
    },
    {
        id: -1,
        title: 'Webull',
        subtitle: 'Referral Link for Webull investing account. Frequently have give aways for new accounts.',
        imageUrl: 'https://i.ibb.co/rbjKPn8/download.png',
        link: 'https://a.webull.com/iHwxPfubr2m6KIhWcM',
        type: ['financial services', 'business & career', 'miscellaneous']
    },
    {
        id: -2,
        title: 'CrunchLabs',
        subtitle: '$10 off your first subscription order.',
        imageUrl: 'https://i.ibb.co/dksVV29/2-RGPd-P74bg8mx3sz0-USEp-eh.jpg',
        link: 'https://crunchlabs.refr.cc/danielwernz',
        type: ['entertainment', 'games & toys', 'miscellaneous', 'hobbies & collectibles', 'gift & flowers']
    },
    {
        id: 1,
        title: 'Lego Pinterest',
        subtitle: 'LEGOS',
        imageUrl: 'https://i.ibb.co/5MPTjFQ/lego.jpg',
        link: 'https://www.pinterest.com/dwernz/lego-sets/',
        type: ['department store', 'games & toys', 'gift & flowers', 'hobbies & collectibles']
    },
    {
        id: 2,
        title: 'Bake Me A Wish',
        subtitle: '	Bake Me A Wish! | Save 15% on Gifts with Code: CELEBRATE',
        imageUrl: 'https://i.ibb.co/CvQsfGM/images.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1335732.10000321&type=3&subid=0',
        type: ['food & drink', 'gift & flowers']
    },
    {
        // Pinterest
        id: 3,
        title: 'Chesapeake Fine Foods',
        subtitle: 'Chesapeake Bay Crab Cakes & More -- $10 off $99+ (use code: SE99A23)',
        imageUrl: 'https://i.ibb.co/94ZtxGX/a-601-2.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=582903.288&type=3&subid=0',
        type: ['department store', 'family', 'food & drink', 'gift & flowers', 'home & living']
    },
    {
        id: 4,
        title: 'Pearson Education eText',
        subtitle: 'Get Access to over 1500 popular eTexts with Pearson+. Plans starting at $9.99/month',
        imageUrl: 'https://i.ibb.co/Xy9TWkB/main.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1338946.7&type=3&subid=0',
        type: ['business & career', 'family']
    },
    {
        id: 5,
        title: 'Proflowers',
        subtitle: 'Shop Flowers & Gifts!',
        imageUrl: 'https://i.ibb.co/8464LQM/what-is-pro-flowers.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1151236.142&type=3&subid=0',
        type: ['food & drink', 'gift & flowers']
    },
    {
        id: 6,
        title: 'Fire Maple',
        subtitle: '5% off when you sign up. Use code [ FM5 ]',
        imageUrl: 'https://i.ibb.co/5kK5BkP/backpacking-tea-kettle-coffee-pot-251138.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1322756.11&type=3&subid=0',
        type: ['department store', 'food & drink', 'home & living', 'sports & fitness', 'travel']
    },
    {
        id: 7,
        title: 'ELSA Speak',
        subtitle: 'Join millions of learners speaking better English with ELSA. Download the app for free!',
        imageUrl: 'https://i.ibb.co/pLRJyjc/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1349799.4&type=3&subid=0',
        type: ['business & career', 'family', 'games & toys', 'internet & online', 'travel']
    },
    {
        id: 8,
        title: 'FTD Flowers',
        subtitle: 'Shop Flowers & Gifts at FTD',
        imageUrl: 'https://i.ibb.co/LxFZmPp/images.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1153143.3&type=3&subid=0',
        type: ['food & drink', 'gift & flowers']
    },
    {
        id: 9,
        title: 'CPO Outlets',
        subtitle: 'Shop outdoor power tools and equipment from top brands like DEWALT, Makita, Black & Decker, and More!',
        imageUrl: 'https://i.ibb.co/sPXKpq2/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1271238.8&type=3&subid=0',
        type: ['home & living', 'office']
    },
    {
        id: 10,
        title: 'Aquarius Casino Resort',
        subtitle: '$20 Breakfast Credit at Cafe Aquarius',
        imageUrl: 'https://i.ibb.co/RYGbxKM/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1234103.5&type=3&subid=0',
        type: ['travel']
    },
    {
        id: 11,
        title: 'Mobile Pixels',
        subtitle: '10% off coupon "MPR10',
        imageUrl: 'https://i.ibb.co/mGYmr6r/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1182618.6&type=3&subid=0',
        type: ['family', 'games & toys', 'office']
    },
    {
        id: 12,
        title: 'Careerist',
        subtitle: 'Careerist Summer SaleL Discounts Up to 50%',
        imageUrl: 'https://i.ibb.co/jfWhvdy/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1296385.18&type=3&subid=0',
        type: ['family', 'business & career', 'internet & online', 'office']
    },
    {
        id: 13,
        title: 'The STRAT Hotel',
        subtitle: 'Mid-Week Room Special - Starting at $25',
        imageUrl: 'https://i.ibb.co/HYmdbcK/caption.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1091347.13&type=3&subid=0',
        type: ['gift & flowers', 'travel']
    },
    {
        id: 14,
        title: 'Rover.com',
        subtitle: 'Check out the Rover Store, Free Shipping on Orders over $59',
        imageUrl: 'https://i.ibb.co/1rm6y6P/Rover-Sitter-Image-2.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1369077.104&type=3&subid=0',
        type: ['business & career', 'department store', 'family', 'miscellaneous']
    },
    {
        id: 15,
        title: 'TEMPTATION CANCUN RESORT',
        subtitle: 'Book Temptation Cancun Resort! The best adults only resort in Cancun, Mexico!',
        imageUrl: 'https://i.ibb.co/yfCj8c7/376884138.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=754244.4&type=3&subid=0',
        type: ['mature/adult', 'travel']
    },
    {
        id: 16,
        title: 'Desire Riviera Maya Pearl Resort',
        subtitle: 'Located in Puerto Morelos, Mexico! A couples only all inclusive experience!',
        imageUrl: 'https://i.ibb.co/1rMkPPF/115944792.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=754269.7&type=3&subid=0',
        type: ['hobbies & collectibles', 'travel']
    },
    {
        id: 17,
        title: 'KonMari',
        subtitle: `Marie Kondo's Favorite Low-Waste & Sustainable Home Goods - Free Shipping on Orders Over $150`,
        imageUrl: 'https://i.ibb.co/2d4YTkm/Getting-started-with-the-Kon-Mari-Method.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=745641.29&type=3&subid=0',
        type: ['department store', 'health & beauty', 'home & living', 'office']
    },
    {
        id: 18,
        title: 'Sam Ash Music Marketing, LLC',
        subtitle: 'Shop Used Musical Instruments, Used Guitars, Used Drums, Used Pianos, Used Pro Audio Gear, Used DJ Equipment',
        imageUrl: 'https://i.ibb.co/WyHxP19/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=490021.10000635&type=3&subid=0',
        type: ['hobbies & collectibles']
    },
    {
        id: 19,
        title: 'Cafe Britt',
        subtitle: 'Free Shipping with purchase of 3 bags',
        imageUrl: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=358111.10001468&bids=358111.10001468&type=3&subid=0',
        type: ['food & drink', 'gift & flowers', 'home & living', 'office']
    },
    {
        id: 20,
        title: 'Alibris: Books, Music, & Movies',
        subtitle: 'Your source for Books, Movies and Music',
        imageUrl: 'https://i.ibb.co/GvT3TdX/tumblr-static-249xfn4kvw4kwogooogcwgogk-2048-v2.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=189673.10001309&type=3&subid=0',
        type: ['family', 'gift & flowers']
    },
    {
        id: 21,
        title: 'National Business Furniture, Inc',
        subtitle: 'Save 9% site wide when you sign up for email newsletters!',
        imageUrl: 'https://i.ibb.co/9GVdL4z/MRN-14243-3-Ash-Black.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=684218.10000472&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['business & career', 'office']
    },
    {
        id: 22,
        title: 'Senior.com',
        subtitle: '5% discount that can be used off a total order for customers with code SENIORSAVINGS',
        imageUrl: 'https://i.ibb.co/3Sjspxm/Senior-com-Logo-RGB-2020-ba2581d6-19e8-48bc-94f7-1ed240916719.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1195023.19&type=3&subid=0',
        type: ['department store', 'health & beauty', 'home & living', 'miscellaneous', 'office']
    },
    {
        id: 23,
        title: 'Garrett Wade',
        subtitle: 'Save 10% on Orders $100 or More. Use code GW100.',
        imageUrl: 'https://i.ibb.co/Y82P6X2/75a0150-spooncarvingset-main-4-19304-54573.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1100756.209&type=3&subid=0',
        type: ['gift & flowers', 'home & living', 'sports & fitness']
    },
    {
        id: 24,
        title: 'A4C',
        subtitle: 'EXTRA 5% OFF WHEN YOU BUY 3 OR MORE - COUPON CODE A4C5OFF',
        imageUrl: 'https://i.ibb.co/t3hh7vb/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=303249.10000131&type=3&subid=0',
        type: ['auto', 'telecommunications']
    },
    {
        id: 25,
        title: 'Paul Smith USA',
        subtitle: `Paul Smith is one of Britain's best-loved designers`,
        imageUrl: 'https://i.ibb.co/jVTpgyz/Main-Image.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1355412.4&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 26,
        title: 'GAMIVO',
        subtitle: 'Unlock Unbeatable Deals at Gamivo - Save up to 80% on Games and Software! 🎮💰',
        imageUrl: 'https://i.ibb.co/6XC87dS/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1327140.10&type=3&subid=0',
        type: ['games & toys', 'gift & flowers', 'internet & online']
    },
    {
        id: 27,
        title: 'ChicMe',
        subtitle: 'GET 55% OFF WHEN OVER 99 USD WITH CODE: XX9955',
        imageUrl: 'https://i.ibb.co/X44NSpB/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1329800.15&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 28,
        title: 'Kasper Clothing',
        subtitle: 'Newsletter Sign ups for 20% off your first order.',
        imageUrl: 'https://i.ibb.co/FWmkVxS/11055-0726-Single-Left-D.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1309715.3&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 29,
        title: 'KORRES',
        subtitle: 'Earn points on every purchase! Earn up to $1.50 on every $1 spent',
        imageUrl: 'https://i.ibb.co/VJ6pcP2/Asset-14-100.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1344492.5&type=3&subid=0',
        type: ['department store', 'health & beauty', 'home & living']
    },
    {
        id: 30,
        title: 'My Golfing Store',
        subtitle: 'Save 55% off Eagle Eye Rangefinder - Special Sale',
        imageUrl: 'https://i.ibb.co/SB5ZSvB/photo-eagle-eye-800y-user.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1293629.2&type=3&subid=0',
        type: ['department store', 'games & toys', 'gift & flowers', 'miscellaneous', 'sports & fitness']
    },
    {
        id: 31,
        title: 'Olivela',
        subtitle: '20% off First Orders',
        imageUrl: 'https://i.ibb.co/WPBy5MH/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1301106.7&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 32,
        title: 'Gabriela Hearst',
        subtitle: `Shop Gabriela Hearst Women's and Men's Handbags and RTW`,
        imageUrl: 'https://i.ibb.co/kSWs7G7/Gabriela-Hearst-Crops-10-3000x.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1303514.3&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 33,
        title: 'Rael',
        subtitle: 'Free Shipping Over $50.',
        imageUrl: 'https://i.ibb.co/P41k6yB/Collection-Shop-All.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1262357.4&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 34,
        title: 'SkyGolf',
        subtitle: 'Play better, play smarter, play faster and have more FUN with SkyCaddie!',
        imageUrl: 'https://i.ibb.co/GtmYM29/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1254482.3&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['miscellaneous', 'sports & fitness']
    },
    {
        id: 35,
        title: 'WonderFold Wagon',
        subtitle: 'Explore Fearlessly. The Perfect Stroller Wagon is Waiting.',
        imageUrl: 'https://i.ibb.co/7YK8k1p/230616-W4-ELT-BLK-2.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1245274.4&type=3&subid=0',
        type: ['clothing & accessories', 'family']
    },
    {
        id: 36,
        title: 'Frameist',
        subtitle: 'Shop Largest Selection of Custom Picture Frames Online. 1000+ Frames to Choose From.',
        imageUrl: 'https://i.ibb.co/qJbsL1Y/minh-pham-Ot-XADk-Uh3-I-unsplash.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1242825.3&type=3&subid=0',
        type: ['department store', 'gift & flowers', 'hobbies & collectibles', 'home & living']
    },
    {
        id: 37,
        title: 'Quizplus',
        subtitle: '	5% Off All Quizplus Plans, Use Coupon QPRA5',
        imageUrl: 'https://i.ibb.co/NxYFnjQ/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1234368.14&type=3&subid=0',
        type: ['family', 'internet & online', 'mature/adult']
    },
    {
        id: 38,
        title: 'AnimalBiome',
        subtitle: 'Improve the Gut Health of Cats & Dogs! Use Code SAVEAB-15 for 15% off for 1st time customers',
        imageUrl: 'https://i.ibb.co/XVt4GNQ/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1214170.7&type=3&subid=0',
        type: ['family', 'health & beauty', 'miscellaneous']
    },
    {
        id: 39,
        title: 'WJD Exclusives',
        subtitle: 'Real Gold & Diamond Chains, Bracelets, Earrings, Rings & more. 24/7 customer support. Free fast shipping & returns.',
        imageUrl: 'https://i.ibb.co/wLqK2t9/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1353421.3&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 40,
        title: 'Signature HomeStyles',
        subtitle: 'New Customer Offer- Free Shipping and $15 off your first order of $75 or more with code NEWSIG15',
        imageUrl: 'https://i.ibb.co/VjcRBRZ/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1358100.54&type=3&subid=0',
        type: ['department store', 'gift & flowers', 'home & living']
    },
    {
        id: 41,
        title: 'Kiierr International',
        subtitle: 'Learn More about Kiierr Laser Caps',
        imageUrl: 'https://i.ibb.co/D8NmVtt/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1214199.6&type=3&subid=0',
        type: ['health & beauty', 'miscellaneous']
    },
    {
        id: 42,
        title: 'GXVE Beauty',
        subtitle: `Check it out! Enjoy 25% off on GXVE MUST-HAVE'S. Enter code: BEAUTY25 at checkout.`,
        imageUrl: 'https://i.ibb.co/zZfCD8z/images.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1269132.22&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty', 'miscellaneous']
    },
    {
        id: 43,
        title: 'The Laughing Giraffe',
        subtitle: 'Shop for wholesale baby & toddler blank clothing for imprinting',
        imageUrl: 'https://i.ibb.co/3NnsCvx/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1187585.3&type=3&subid=0',
        type: ['business & career', 'clothing & accessories', 'family', 'hobbies & collectibles', 'mature/adult']
    },
    {
        id: 44,
        title: 'Sailor-Sailor',
        subtitle: '15% off when you sign up! Use code HELLOSAILOR',
        imageUrl: 'https://i.ibb.co/XD9wb5s/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1191334.4&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 45,
        title: 'OLAPLEX',
        subtitle: 'Patented OLAPLEX Bond Building Technology™ in every step is what makes OLAPLEX the complete hair repair system–your skincare for haircare.',
        imageUrl: 'https://i.ibb.co/PhzgJ1x/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1280452.3&type=3&subid=0',
        type: ['health & beauty', 'home & living']
    },
    {
        id: 46,
        title: 'Samsung B2B',
        subtitle: 'Pre-order Galaxy Z Fold5 with a Care+ for Business Accidental Damage Plan and get a free eligible case per device with a valid Samsung Business Account.',
        imageUrl: 'https://i.ibb.co/qd5LpJP/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1169279.39&type=3&subid=0',
        type: ['business & career', 'computers & electronics', 'office']
    },
    {
        id: 47,
        title: 'GOODWINXXII',
        subtitle: 'FREE Shipping on all US Orders Over $150',
        imageUrl: 'https://i.ibb.co/88KMpjp/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1157002.23&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'gift & flowers']
    },

    {
        id: 48,
        title: 'Agency Skincare',
        subtitle: 'Start Your 30-Day Trial',
        imageUrl: 'https://i.ibb.co/8NhmcJ0/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1148170.4&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 49,
        title: 'The Jacket Maker',
        subtitle: 'Shop Real Leather Jacket at The Jacket Maker',
        imageUrl: 'https://i.ibb.co/Hz3M4fZ/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1166863.101&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 50,
        title: 'PowerStep',
        subtitle: 'Get 10% Off at PowerStep with Newsletter sign-up',
        imageUrl: 'https://i.ibb.co/YtYZ57j/Microsoft-Teams-image-93-210x.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1139659.7&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty', 'sports & fitness']
    },
    {
        id: 51,
        title: 'Turtle Wax US',
        subtitle: '20% Off Your Entire Order When You Spend over $60 When You Use Code: XMAS20',
        imageUrl: 'https://i.ibb.co/7v2qGFs/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1127661.42&type=3&subid=0',
        type: ['auto', 'miscellaneous']
    },
    {
        id: 52,
        title: 'Tru Niagen',
        subtitle: `Defy Aging with Tru Niagen: Daily Use of Tru Niagen Can Help Counter the Effects of Aging Caused by Daily Stressors Inside Your Body`,
        imageUrl: 'https://i.ibb.co/XVbHCmW/microscope.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1168591.9&type=3&subid=0',
        type: ['health & beauty', 'miscellaneous']
    },
    {
        id: 53,
        title: 'Melio',
        subtitle: 'Free Accounts Payable software, on your terms!',
        imageUrl: 'https://i.ibb.co/fp00J2Q/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1298679.3&type=3&subid=0',
        type: ['business & career', 'financial services', 'miscellaneous']
    },
    {
        id: 54,
        title: 'Buddha Power Store',
        subtitle: 'Sign Up To Our Newsletter And Receive 15% Discount',
        imageUrl: 'https://i.ibb.co/KqVvbjZ/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1089768.10&type=3&subid=0',
        type: ['clothing & accessories', 'miscellaneous']
    },
    {
        id: 55,
        title: 'Nuun',
        subtitle: '20% off your first order!',
        imageUrl: 'https://i.ibb.co/xgCKGYP/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1166054.6&type=3&subid=0',
        type: ['food & drink', 'health & beauty', 'sports & fitness']
    },
    {
        id: 56,
        title: 'LEMONKIND',
        subtitle: 'Get 16% off this amazing 3-Day Juice Cleanse by Lemonkind® - 24 Plant-Based Juices to Reset your Metabolism and Jumpstart your diet',
        imageUrl: 'https://i.ibb.co/MpDYstf/images.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1057101.10&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 57,
        title: 'SHOWROOM',
        subtitle: 'Save 10% on your first order with code Welcome10',
        imageUrl: 'https://i.ibb.co/HDNKNbz/Copy-of-Showroom-Supporting-Logo-STACKED-BLK-1-160x.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1315538.4&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['clothing & accessories', 'home & living']
    },
    {
        id: 58,
        title: 'Adrianna Papell',
        subtitle: 'Save up to 70% off Last Chance Styles',
        imageUrl: 'https://i.ibb.co/JsF45HH/i-shgcdn.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1054065.74&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        // Pinterest
        id: 59,
        title: 'Samsung',
        subtitle: `Save up to 30% off with Samsung's Education Discount Program.`,
        imageUrl: 'https://i.ibb.co/k5PPwy3/images.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1122500.51&type=3&subid=0',
        type: ['business & career', 'computers & electronics', 'office']
    },
    {
        id: 60,
        title: 'Collars & Co.',
        subtitle: 'The Original Dress Collar Polo',
        imageUrl: 'https://i.ibb.co/NrGggTv/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=993888.4&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 61,
        title: 'Quicklly',
        subtitle: 'Authentic Indian Sweets | Use QUICKLLY15 to Get 15% Off',
        imageUrl: 'https://i.ibb.co/crL0TsN/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1301901.284&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['food & drink', 'miscellaneous']
    },
    {
        // Pinterest
        id: 62,
        title: 'Jos. A. Bank',
        subtitle: 'Shop Jos.A.Bank',
        imageUrl: 'https://i.ibb.co/FYVGZZb/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1139580.11305&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 63,
        title: 'Betsey Johnson',
        subtitle: 'SIGN UP FOR EMAILS AND GET 20% OFF YOUR NEXT ORDER!',
        imageUrl: 'https://i.ibb.co/kcQ1ZbS/Metallic-Light-Pink.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=980634.433&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 64,
        title: 'Steve Madden',
        subtitle: 'Steve Madden now working with Afterpay! Split your payment into four equal sized payments- Shop Now!',
        imageUrl: 'https://i.ibb.co/ckNxTN6/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=844221.623&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 65,
        title: 'Benefit Cosmetics LLC',
        subtitle: 'Get 15% off when you sign up for emails.',
        imageUrl: 'https://i.ibb.co/XLnstRB/brows-fifty-fifty.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=972257.10001598&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'gift & flowers', 'health & beauty']
    },
    {
        id: 66,
        title: 'Cutter & Buck, Inc.',
        subtitle: `ProShop at Cutter & Buck, Cutter & Buck has built a legacy by making top-tier golf apparel. Now, we’ve pulled together the best of our performance fabrics and styles, so you always know where to find the right gear for your game.`,
        imageUrl: 'https://i.ibb.co/4tJHcwQ/cb-story.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=57405.10000293&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 67,
        title: 'Sharper Image',
        subtitle: 'Endless Summer Sale $25 Off on Orders $75+. Use code: END25. Valid 8/14 through 8/18 at Midnight!',
        imageUrl: 'https://i.ibb.co/pzNrM0V/images.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=291398.10001286&type=3&subid=0',
        type: ['family', 'games & toys', 'gift & flowers', 'home & living']
    },
    {
        id: 68,
        title: 'FragranceNet.com',
        subtitle: 'Free Shipping (No Minimum) When You Join Our Coupon List!',
        imageUrl: 'https://i.ibb.co/Rh3S7Gz/about-us-landing.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=507761.10001164&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'gift & flowers', 'health & beauty']
    },
    {
        id: 69,
        title: 'Elvie Affiliate US',
        subtitle: 'Smarter technology for women | Elvie Trainer and Elvie Pump',
        imageUrl: 'https://i.ibb.co/MnrDK2g/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=886470.8&type=3&subid=0',
        type: ['family', 'health & beauty', 'sports & fitness']
    },
    {
        // Pinterest
        id: 70,
        title: 'Watch Warehouse',
        subtitle: `Shop Designer Watches & Accessories at Watch Warehouse`,
        imageUrl: 'https://i.ibb.co/98t5Cv2/va1.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1333800.4&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 71,
        title: 'Journee Collection',
        subtitle: 'Get Free Shipping on orders over $75',
        imageUrl: 'https://i.ibb.co/CnwWzmM/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1264541.3&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 72,
        title: 'ecobee',
        subtitle: 'Shop the Smart Sensor at ecobee.com!',
        imageUrl: 'https://i.ibb.co/VgrxjxM/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=805849.205&type=3&subid=0',
        type: ['home & living']
    },
    {
        id: 73,
        title: 'JanSport',
        subtitle: 'End of Season Sale 30% Off Select Styles',
        imageUrl: 'https://i.ibb.co/sqwBcVr/link-42224.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=528972.216&bids=528972.216&type=3&subid=0',
        type: ['clothing & accessories', 'office', 'sports & fitness', 'department store']
    },
    {
        id: 74,
        title: 'Bedrosians Tile & Stone',
        subtitle: 'Get 30% Off Even More From Our Vinyl Markdowns with Promo Code DIYVINYLFLOOR',
        imageUrl: 'https://i.ibb.co/BKS7WKQ/xyjcz5tatv9si6mxl2568u074esl.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=705297.56&type=3&subid=0',
        type: ['home & living']
    },
    {
        id: 75,
        title: 'Popsockets',
        subtitle: 'Shop Premium PopSockets Grips and enjoy Free Shipping on $40+ orders!',
        imageUrl: 'https://i.ibb.co/j8ZXnCP/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=919977.8&type=3&subid=0',
        type: ['clothing & accessories', 'games & toys', 'gift & flowers', 'miscellaneous']
    },
    {
        id: 76,
        title: 'Fox Rent a Car',
        subtitle: 'Pay now and save! Up to an EXTRA 12% OFF on pre-paid US car rates. Code applied automatically when clicking the "BOOK NOW" button below. Blackouts may apply.',
        imageUrl: 'https://i.ibb.co/rp9Vxq2/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=977192.22&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['auto', 'travel']
    },
    {
        id: 77,
        title: 'NordVPN',
        subtitle: 'NordVPN - Virtual Private Network',
        imageUrl: 'https://i.ibb.co/x76fP9w/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=572706.12&type=3&subid=0',
        type: ['business & career', 'internet & online', 'telecommunications']
    },
    {
        id: 78,
        title: 'keepersecurity.com',
        subtitle: `Forgot your Password? Keeper's got you covered! Get 40% OFF Keeper's Password Manager when you Shop Today!`,
        imageUrl: 'https://i.ibb.co/9ZQMR0y/link-42966.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1261408.165&bids=1261408.165&type=3&subid=0',
        type: ['business & career', 'internet & online']
    },
    {
        id: 79,
        title: 'Ivacy VPN',
        subtitle: 'Ivacy VPN - BEST VPN DEAL IS LIVE. 5-Year Plan in Just $1 /mo for 10 Devices. Free Premium Password Manager.',
        imageUrl: 'https://i.ibb.co/qmjKZS2/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=769922.331&type=3&subid=0',
        type: ['internet & online', 'miscellaneous', 'telecommunications']
    },
    {
        id: 80,
        title: 'FutureLearn Limited',
        subtitle: 'FutureLearn offers and array of online study options',
        imageUrl: 'https://i.ibb.co/zVFt9P3/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=871625.113&type=3&subid=0',
        type: ['business & career', 'family', 'health & beauty', 'internet & online', 'miscellaneous']
    },
    {
        // Pinterest
        id: 81,
        title: 'factorydirectfilters.com',
        subtitle: 'Factory Direct Filters',
        imageUrl: 'https://i.ibb.co/cXMGd81/download.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1358050.141&type=3&subid=0',
        type: ['business & career', 'department store', 'home & living', 'office']
    },
    {
        id: 82,
        title: 'PandaHall',
        subtitle: 'Get Rewarded for First Login! $40 Coupons Await in APP',
        imageUrl: 'https://i.ibb.co/jwxxcxH/new-logo.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=944971.3386&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 83,
        title: 'Air Filters Delivered',
        subtitle: '	Free shipping on all orders over $65!',
        imageUrl: 'https://i.ibb.co/60xQxf9/air-filters-deilvered-200x.webp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1358020.20484&type=3&subid=0',
        type: ['department store', 'family', 'health & beauty', 'home & living', 'miscellaneous']
    },
    {
        id: 84,
        title: 'PureVPN',
        subtitle: 'PureVPN Exclusive Discount Offer',
        imageUrl: 'https://i.ibb.co/5TsnJKk/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1077999.376&type=3&subid=0',
        type: ['family', 'internet & online']
    },
    {
        id: 85,
        title: 'Remodel Your Home',
        subtitle: '20% OFF ALL ROOFING SERVICES',
        imageUrl: 'https://i.ibb.co/VNDZd2N/link-50744.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1389288.3&type=3&subid=0',
        type: ['home & living']
    },
    {
        id: 86,
        title: 'Bali Bras',
        subtitle: 'Get 20% off when you sign up for exclusive sales and offers!',
        imageUrl: 'https://i.ibb.co/0Vwr1GP/link-46392.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1242789.3&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty']
    },
    {
        id: 87,
        title: 'Maidenform',
        subtitle: 'Mix & Match Panties, endless combinations to choose from!',
        imageUrl: 'https://i.ibb.co/R2KHTyk/link-43954.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1242784.10&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty']
    },
    {
        id: 88,
        title: 'Mountainside Medical Equipment, Inc.',
        subtitle: '10000 Medical Products to Choose From',
        imageUrl: 'https://i.ibb.co/n3t0v1H/link-41691.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=439400.18&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 89,
        title: 'italist US',
        subtitle: `Discover luxury homewares created by some of the best brands in the world, such as Le Botteghe su Gologone, Once Milano, Larusmiani, and Lo Decor. at italist.com. SAVE up to 40% and enjoy FREE GLOBAL EXPRESS SHIPPING!`,
        imageUrl: 'https://i.ibb.co/484103y/link-41132.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1336850.251&type=3&subid=0',
        type: ['clothing & accessories']
    },
    {
        id: 90,
        title: 'NHC Vitamins',
        subtitle: '15% Off When You Spend $100 - Use Code: 15OFFNHC',
        imageUrl: 'https://i.ibb.co/T0G2TJ1/link-40568.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=742931.535&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 91,
        title: 'Canadapetcare',
        subtitle: 'Get 12% OFF Nexgard with Code NXGSAVE12',
        imageUrl: 'https://i.ibb.co/Yh9YYt9/link-39866.gif',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=354333.898&type=3&subid=0',
        type: ['health & beauty', 'department store', 'home & living']
    },
    {
        id: 92,
        title: 'FlowerShopping.com',
        subtitle: 'Order Today! Save 10% + No Sales Tax',
        imageUrl: 'https://i.ibb.co/Yj5pTh4/link-38726.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=801368.69&type=3&subid=0',
        type: ['gift & flowers', 'department store', 'internet & online']
    },
    {
        id: 93,
        title: 'HerbsPro',
        subtitle: '10% off on all herbal supplements. Use Promo Code - BTS2023 at checkout',
        imageUrl: 'https://i.ibb.co/pfJzxkf/link-38469.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=295006.307&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 94,
        title: 'Aquasana Home Water Filters',
        subtitle: 'Purchase the OptimH2O Reverse Osmosis System + Claryum Filtration Technology at Aquasana.com',
        imageUrl: 'https://i.ibb.co/8Nvzz5B/link-36667.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1108576.10000723&bids=1108576.10000723&type=3&subid=0',
        type: ['food & drink', 'health & beauty', 'home & living', 'department store']
    },
    {
        id: 95,
        title: 'Iolo technologies, LLC',
        subtitle: 'Shop patented technology and award-winning software that repairs, optimizes, and protects computers and digital devices!',
        imageUrl: 'https://i.ibb.co/TcwzG1p/link-25039.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1207691.10000360&type=3&subid=0',
        type: ['computers & electronics', 'office', 'internet & online', 'business & career']
    },
    {
        id: 96,
        title: 'Hanes.com',
        subtitle: `Ships free with $40+ purchase`,
        imageUrl: 'https://i.ibb.co/0QZtjRS/link-24366.gif',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1242778.10000257&bids=1242778.10000257&subid=0&type=4',
        type: ['clothing & accessories', 'department store', 'sports & fitness']
    },
    {
        id: 97,
        title: 'Champion.com',
        subtitle: 'Saluting service members with an additional 10% off.',
        imageUrl: 'https://i.ibb.co/nRbqnHB/link-3156.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1242774.10001515&type=3&subid=0',
        type: ['clothing & accessories', 'department store', 'sports & fitness', 'gifts & flowers']
    },
    {
        id: 98,
        title: 'onehanesplace.com (Hanesbrands Inc.)',
        subtitle: '40% Off Select Bras + Buy 3 or More, Save an Addional 20%',
        imageUrl: 'https://i.ibb.co/wYk5yYm/link-2856.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1242780.10003966&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty', 'sports & fitness', 'department store', 'miscellaneous']
    },
    {
        id: 99,
        title: 'VitaMedica',
        subtitle: '15% Off With Newsletter Signup',
        imageUrl: 'https://i.ibb.co/17tRDrj/link-50734.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1391412.10&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty', 'home & living', 'department store']
    },
    {
        id: 100,
        title: 'Sensibo',
        subtitle: `Upgrade your home's comfort and energy efficiency with Sensibo - UP to 30% OFF!`,
        imageUrl: 'https://i.ibb.co/g7WtDK5/link-50234.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1396464.15&type=3&subid=0',
        type: ['computer & electronics', 'office', 'home & living', 'department store']
    },
    {
        id: 101,
        title: 'CPO Dewalt',
        subtitle: 'Dewalt Power Tools',
        imageUrl: 'https://i.ibb.co/MDCH3k7/link-50212.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1277255.13&subid=0&type=4',
        type: ['home & living']
    },
    {
        id: 102,
        title: 'Connected Apparel',
        subtitle: 'Get 25% Back On Your First Purchase',
        imageUrl: 'https://i.ibb.co/TqGfwxD/link-50166.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1247401.40&type=3&subid=0',
        type: ['clothing & accessories', 'gift & flowers', 'department store']
    },
    {
        id: 103,
        title: 'Gonza',
        subtitle: '20% OFF First Order with code GONZASUMMERAF',
        imageUrl: 'https://i.ibb.co/MhnhDGz/link-48066.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1091240.19&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness', 'department store', 'travel']
    },
    {
        id: 104,
        title: 'LearnWorlds',
        subtitle: 'LearnWorlds is your one-stop platform to create, sell, promote and monetize your digital content. Start your free trial today!',
        imageUrl: 'https://i.ibb.co/tXHVgnD/link-47812.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1079430.3&type=3&subid=0',
        type: ['internet & online', 'miscellaneous']
    },
    {
        id: 105,
        title: 'Microsoft for Business',
        subtitle: 'Shop Surface for Business laptops and tablets',
        imageUrl: 'https://i.ibb.co/LhqnQ0K/link-46107.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1321193.46&type=3&subid=0',
        type: ['computers & electronics', 'office', 'internet & online']
    },
    {
        id: 106,
        title: 'Laura Geller',
        subtitle: `Check out Laura Geller's Holiday Preview Sale! Find savings on all products up to 60% off. Plus, take an extra 10% off with coupon H10.`,
        imageUrl: 'https://i.ibb.co/w4BGXQ1/link-45314.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=877143.751&bids=877143.751&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 107,
        title: 'Julep Beauty',
        subtitle: 'Save on everything with Julep! Get 35% off site wide with coupon code "35JL" at check out. Some exclusions apply. Save more with free US shipping on all orders of $40+. Offer valid 9/1 - 9/30.',
        imageUrl: 'https://i.ibb.co/VWZTf9b/link-45313.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=763334.298&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 108,
        title: 'BN3TH',
        subtitle: 'Get 10% OFF on ultra-soft & breathable underwears at BN3TH! Use coupon code: BR10',
        imageUrl: 'https://i.ibb.co/mNwnSc3/link-45106.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1151453.67&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness', 'department store']
    },
    {
        id: 109,
        title: 'FineStationary',
        subtitle: 'Save 25% off sitewide on any order. Exp. 10/10. Use code AFLRV25',
        imageUrl: 'https://i.ibb.co/ZgN8wHk/link-44952.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=709860.6&type=3&subid=0',
        type: ['gift & flowers', 'department store']
    },
    {
        id: 110,
        title: 'FarmRio',
        subtitle: 'Warm welcome: get 15% off your 1st purchase with code WELCOME15',
        imageUrl: 'https://i.ibb.co/nBFXWND/link-44912.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1033558.199&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 111,
        title: 'RoseWe',
        subtitle: 'Rosewe Chic One Step Outfit: UP TO 69% OFF!',
        imageUrl: 'https://i.ibb.co/Hp6c5Vh/link-44379.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=743092.111&type=3&subid=0',
        type: ['clothing & accessories', 'mature/adult', 'sports & fitness', 'department store']
    },
    {
        id: 112,
        title: 'Real Ketones',
        subtitle: '10% OFF! Use Code RK10 At Checkout',
        imageUrl: 'https://i.ibb.co/N1pQqWC/images.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=660525.54&type=3&subid=0',
        type: ['health & beauty', 'home & living']
    },
    {
        id: 113,
        title: 'Master & Dynamic US',
        subtitle: 'Listen Your Way: The MW50+ 2-in-1 Wireless On-Ear + Over-Ear Headphones',
        imageUrl: 'https://i.ibb.co/sgtCWtm/link-44092.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=900345.11&type=3&subid=0',
        type: ['clothing & accessories', 'computer & electronics', 'sports & fitness']
    },
    {
        id: 114,
        title: 'Vince Unfold',
        subtitle: 'Rent your favorite Vince looks for one monthly fee. No commitments. Complimentary dry cleaning and free shipping. Join Vince Unfold today!',
        imageUrl: 'https://i.ibb.co/txqSfBS/link-44029.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=661566.7&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['clothing & accessories', 'mature/adult', 'internet & online', 'department store', 'miscellaneous']
    },
    {
        id: 115,
        title: 'Blockchain Council',
        subtitle: 'Unlimited Training Subscription',
        imageUrl: 'https://i.ibb.co/NSRNwC7/link-43395.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=980864.34&type=3&subid=0',
        type: ['internet & online', 'miscellaneous']
    },
    {
        id: 116,
        title: 'Pat McGrath',
        subtitle: 'SHOP WITHOUT CAUTION ⚡ Save 10% on your first purchase with code MAJOR10 (offer valid for new customers only)',
        imageUrl: 'https://i.ibb.co/YRSyfhS/link-43923.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1133393.118&type=3&subid=0',
        type: ['gift & flowers']
    },
    {
        id: 117,
        title: 'Madda Fella',
        subtitle: 'Sign Up For Our Newsletter & Receive 25% Off Your Order! Madda Fella. Tropical Menswear',
        imageUrl: 'https://i.ibb.co/RhgSXgj/link-43468.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=605630.87&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness', 'department store', 'travel']
    },
    {
        id: 118,
        title: 'The Ayurveda Experience',
        subtitle: 'SAVE upto 42% on Skincare, Haircare & Supplements. NO Parabens! NO Preservatives!',
        imageUrl: 'https://i.ibb.co/fthDsr0/link-43437.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=613323.202&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 119,
        title: 'Vilebrequin',
        subtitle: 'Get 15% Off Your First Online Order with Email Sign-up at Vilebrequin',
        imageUrl: 'https://i.ibb.co/98G0Dx3/link-43322.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=578449.162&type=3&subid=0',
        type: ['clothing & accessories']
    },
    {
        id: 120,
        title: 'Oxygen Plus',
        subtitle: 'Join O+ Auto-Ship Program & Save 25%',
        imageUrl: 'https://i.ibb.co/mDJwKbw/link-43217.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=987953.93&type=3&subid=0',
        type: ['health & beauty', 'sports & fitness', 'miscellaneous']
    },
    {
        id: 121,
        title: 'Koh Gen Do Cosmetics',
        subtitle: 'Final Curtain Sale - up to 40% off. Our select collection of products are moving out of the spotlight and we would love to share this with you before they take their final call.',
        imageUrl: 'https://i.ibb.co/XkRbp1J/link-43083.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=553782.17&type=3&subid=0',
        type: ['clothing & accessories', 'gift & flowers', 'health & beauty', 'department store']
    },
    {
        id: 122,
        title: 'United Scope',
        subtitle: 'Up to 70% off Microscope Cameras at Amscope + Free Shipping in the US.',
        imageUrl: 'https://i.ibb.co/DM7z1bK/link-43027.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=972136.100&type=3&subid=0',
        type: ['computer & electronics', 'games & toys', 'business & center', 'miscellaneous']
    },
    {
        id: 123,
        title: 'Buxom Cosmetics',
        subtitle: 'New Babe -- 15% Off Your Next Purchase When You Sign Up for Our Email List',
        imageUrl: 'https://i.ibb.co/9r18NG3/link-43023.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1245214.16&type=3&subid=0',
        type: ['gift & flowers', 'health & beauty', 'department store']
    },
    {
        id: 124,
        title: `L'AGENCE`,
        subtitle: `15% Off For New Email Subscribers - Sign Up Now at L'AGENCE!`,
        imageUrl: 'https://i.ibb.co/DWjpnpW/link-42841.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1299026.172&type=3&subid=0',
        type: ['clothing & beauty']
    },
    {
        id: 125,
        title: 'Jura Shop',
        subtitle: 'SAVE $500 GIGA 6 Use Code: GIGA500',
        imageUrl: 'https://i.ibb.co/GPTQL8J/link-42829.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=530934.85&type=3&subid=0',
        type: ['computer & electronics', 'food & drink', 'home & living', 'department store']
    },
    {
        id: 126,
        title: 'GeekBuying.com',
        subtitle: '$15 Off for orders over $200 for APP New User with code APPNEW02',
        imageUrl: 'https://i.ibb.co/K9vW46J/link-42078.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1068450.13694&type=3&subid=0',
        type: ['auto', 'computer & electronics', 'games & toys', 'home & living', 'department store']
    },
    {
        id: 127,
        title: 'FitFlop',
        subtitle: 'Sign up to enjoy 15% off your first order!',
        imageUrl: 'https://i.ibb.co/phd1Yw2/link-41223.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=719655.1093&bids=719655.1093&type=3&subid=0',
        type: ['clothing & accessories']
    },
    {
        id: 128,
        title: 'Ticketclub.com',
        subtitle: 'Join the Club save 10-30% off Pesky Service Fees at TicketClub.com',
        imageUrl: 'https://i.ibb.co/bPvn1CD/link-39063.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=494303.107&type=3&subid=0',
        type: ['entertainment', 'mature/adult', 'internet & online', 'miscellaneous']
    },
    {
        id: 129,
        title: 'Olive Young',
        subtitle: 'Shop Korean Beauty Products, Korean Food, K-Pop Albums, Supplements & More',
        imageUrl: 'https://i.ibb.co/k0h5kn0/oy-logo.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1346073.2&type=3&subid=0',
        type: ['entertainment', 'health & beauty', 'department store']
    },
    {
        id: 130,
        title: 'Manduka',
        subtitle: `Shop Mandukas' Yogi Favorite Items and Get Free Shipping on Orders Over $150+!`,
        imageUrl: 'https://i.ibb.co/5jJjDZZ/link-37873.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=943654.475&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness']
    },
    {
        id: 131,
        title: 'Ylang23',
        subtitle: 'Ylang 23 Rewards System: $75 off with 2500 Points, $150 off with 5000 Points, and $300 off with 10,000 Points!',
        imageUrl: 'https://i.ibb.co/pjRQPv2/link-37179.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1082116.2255&type=3&subid=0',
        type: ['clothing & accessories', 'gift & flowers', 'department store']
    },
    {
        id: 132,
        title: 'Bahia Verde Outdoors',
        subtitle: 'Save 10% Off Cape Adirondack Chairs With Code CAPE10',
        imageUrl: 'https://i.ibb.co/THHPp3R/link-50471.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1331930.6&type=3&subid=0',
        type: ['home & living', 'department store','miscellaneous']
    },
    {
        id: 133,
        title: 'Sixt Car Rental',
        subtitle: 'Don´t rent a car, rent THE Car - SAVE up to 30%',
        imageUrl: 'https://i.ibb.co/D51BX3D/link-36665.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1178844.10000293&type=3&subid=0',
        type: ['auto', 'travel']
    },
    {
        id: 134,
        title: 'Microsoft',
        subtitle: 'Shop Microsoft Store - Free Shipping and Free Returns!',
        imageUrl: 'https://i.ibb.co/0cCpnZ4/link-24542.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1388468.10002910&bids=1388468.10002910&type=3&subid=0',
        type: ['computers & electronics', 'games & toys']
    },
    {
        id: 135,
        title: 'Colorful Images',
        subtitle: 'Organize your office & Get $.99 Shipping on ANY $30+ Orders! Exp. 10/10. Use code AFLFS99',
        imageUrl: 'https://i.ibb.co/LZL6QzC/link-2293.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=690939.10000857&type=3&subid=0',
        type: ['hobbies & collections', 'gift & flowers', 'home & living', 'office', 'department store']
    },
    {
        id: 136,
        title: 'Cover FX',
        subtitle: `Check out what's on sale! Up to 50% off!`,
        imageUrl: 'https://ibb.co/W21rMDp',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1074529.3&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 137,
        title: 'Montkush',
        subtitle: '25mg CBD Softgels - A best selling product with 100+ 5 star reviews',
        imageUrl: 'https://ibb.co/5sWBH73',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1065348.9&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 138,
        title: 'SUNNYLiFE US',
        subtitle: 'USE SUNNYLIFE10 - For 10% Off All Full Price Products USA only.',
        imageUrl: 'https://ibb.co/X3cYFN1',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1116347.18&type=3&subid=0',
        type: ['games & toys', 'home & living', 'sports & fitness', 'department store', 'entertainment']
    },
    {
        id: 139,
        title: 'Happy Head',
        subtitle: 'Happy Head reimagines how men and women treat hair loss by providing powerful, customizable solutions',
        imageUrl: 'https://ibb.co/dJr6X75',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1338313.12&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 140,
        title: 'Educate 360',
        subtitle: 'Save big using code Affilliate100 for $100 off courses $895+!',
        imageUrl: 'https://ibb.co/mzsjYWc',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=915143.28&type=3&subid=0',
        type: ['computers & electronics', 'internet & online', 'business & career']
    },
    {
        id: 141,
        title: 'Pro Solutions Direct',
        subtitle: '10% off all IdeaPaint products with the code - IDPRACK10',
        imageUrl: 'https://ibb.co/rmYt1Bv',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1298902.55&type=3&subid=0',
        type: ['home & living', 'department store', 'miscellaneous']
    },
    {
        id: 142,
        title: 'Anine Bing',
        subtitle: 'Enjoy Free Shipping and Exchanges on the ANINE BING Styles You Love.',
        imageUrl: 'https://ibb.co/2qRHPyQ',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1063721.9&type=3&subid=0',
        type: ['clothing & accessories']
    },
    {
        id: 143,
        title: 'Hyper Shop',
        subtitle: '15% off with code: HYPER-CODES',
        imageUrl: 'https://ibb.co/6cKJ1M6',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1011982.184&type=3&subid=0',
        type: ['computer & electronics', 'office']
    },
    {
        id: 144,
        title: 'My Evergreen',
        subtitle: 'Save $10 Off $60 at My Evergreen! Use code LSAVE10 at checkout. Expire offers 9/30.',
        imageUrl: 'https://ibb.co/7j63gNk',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=538455.284&type=3&subid=0',
        type: ['gift & flowers', 'home & living', 'department store']
    },
    {
        id: 145,
        title: 'Vivaterra',
        subtitle: `Join VivaTerra's Email Newsletter & Save 20% Off Your First Purchase of $75+!`,
        imageUrl: 'https://ibb.co/wy6MKNP',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=521984.769&type=3&subid=0',
        type: ['gift & flowers', 'home & living', 'sports & fitness', 'department store']
    },
    {
        id: 146,
        title: 'Wind and Weather',
        subtitle: `Join Wind & Weather's Email Newsletter & Save 20% Off Your First Order of $75+!`,
        imageUrl: 'https://ibb.co/8PsPpcs',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=652664.390&type=3&subid=0',
        type: ['gift & flowers', 'home & living', 'department store']
    },
    {
        id: 147,
        title: 'Mama Sezz',
        subtitle: 'SHOP MamaSezz Plant-Based Food, Free Delivery',
        imageUrl: 'https://ibb.co/MSbXNFX',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1193910.4&type=3&subid=0',
        type: ['food & drink', 'gift & flowers', 'home & living']
    },
    {
        id: 148,
        title: 'Dr. Yates MD Hair Care',
        subtitle: 'Shop All Hair Care',
        imageUrl: 'https://ibb.co/jzF7Qyy',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1214135.8&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 149,
        title: 'cheapflightsfare',
        subtitle: 'Up to 20% off + extra $10 per pax (max $30) on Domestic flight booking. Apply Promo DOM30',
        imageUrl: 'https://i.ibb.co/DMPDWj2/link-50250.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1288780.19&type=3&subid=0',
        type: ['travel']
    },
    {
        id: 150,
        title: 'SPRWMN',
        subtitle: 'Get 15% off your first SPRWMN order!',
        imageUrl: 'https://i.ibb.co/2N1Tzv3/download.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1381343.3&type=3&subid=0&LSNSUBSITE=LSNSUBSITE',
        type: ['clothing & accessories', 'sports & fitness', 'department store']
    },
    {
        id: 151,
        title: 'OK Petroleum Marketplace',
        subtitle: 'Save BIG on Mobil 1, Castrol, Pennzoil, Liquimoly and more !',
        imageUrl: 'https://i.ibb.co/Nm0Y2nm/link-50062.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1347779.3&type=3&subid=0',
        type: ['auto', 'computers & electronics', 'business & career']
    },
    {
        id: 152,
        title: 'Steeped Coffee',
        subtitle: 'Steeped Coffee. Register for our newsletter and get 10% off your first order.',
        imageUrl: 'https://i.ibb.co/1rqy799/link-49444.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1207296.6&type=3&subid=0',
        type: ['food & drink', 'home & living', 'miscellaneous']
    },
    {
        id: 153,
        title: 'Sports&FitnessExchange',
        subtitle: 'Premium Fitness Equipment Up To 70% Off',
        imageUrl: 'https://i.ibb.co/XF994yP/link-49093.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1128880.8&type=3&subid=0',
        type: ['sports & fitness']
    },
    {
        id: 154,
        title: 'Unhidden Beauty',
        subtitle: 'Free shipping on Unhidden Beauty eyeshadow shades - all 100% vegan, cruelty free and paraben free',
        imageUrl: 'https://i.ibb.co/cQ3153s/link-48995.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1188467.5&type=3&subid=0',
        type: ['clothing & accessories', 'gift & flowers', 'health & beauty', 'department store', 'miscellaneous']
    },
    {
        id: 155,
        title: 'Picked by Wine.com',
        subtitle: 'Join Picked by Wine.com and Get $50 Off Your First Box + Free Shipping! | Use code PICK50',
        imageUrl: 'https://i.ibb.co/vh4zTKC/link-48092.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1107965.4&type=3&subid=0',
        type: ['food & drink', 'gift & flowers', 'department store']
    },
    {
        id: 156,
        title: 'florence by mills',
        subtitle: 'Shop our Labor Day Sale and save on skincare, makeup, hair, and more. Save up to 50% off sitewide',
        imageUrl: 'https://i.ibb.co/vmhBC8q/link-47924.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1073279.36&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 157,
        title: 'Package Hopper',
        subtitle: 'Get 10% Off With Coupon Code: ship10off',
        imageUrl: 'https://i.ibb.co/WnPvkST/link-47664.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1013190.5&type=3&subid=0',
        type: ['gift & flowers', 'internet & online', 'department store']
    },
    {
        id: 158,
        title: 'Celestyal Cruises US',
        subtitle: 'Up to 30% off Select Greek Cruises at Celestyal Cruises!',
        imageUrl: 'https://i.ibb.co/TLgdLcn/link-46792.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=970893.9&type=3&subid=0',
        type: ['travel', 'miscellaneous']
    },
    {
        id: 159,
        title: 'Yellowpop',
        subtitle: 'Yellowpop | Shine Bright, Save Energy, Get a LED Neon Today',
        imageUrl: 'https://i.ibb.co/MBt5YRx/link-46455.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=853390.74&type=3&subid=0',
        type: ['hobbies & collections', 'gift & flowers', 'home & living', 'department store']
    },
    {
        id: 160,
        title: 'Nat Geo Wines of the World',
        subtitle: 'Discover 12 critically acclaimed European wines: ONLY $64.99 + bonus gifts worth $68',
        imageUrl: 'https://i.ibb.co/DRB4j5Q/link-46306.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1188424.107&type=3&subid=0',
        type: ['food & drink', 'gift & flowers', 'department store']
    },
    {
        id: 161,
        title: 'BowlersMart.com',
        subtitle: 'Up to 70% Off Top Selling Bowling Balls + Free Shipping!',
        imageUrl: 'https://i.ibb.co/LJcVd5L/link-45538.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1176453.9859&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness', 'entertainment', 'miscellaneous']
    },
    {
        id: 162,
        title: 'Necessaire',
        subtitle: '15% off for New Customers Only. Use code NEWCUSTOMER15 at checkout.',
        imageUrl: 'https://i.ibb.co/ZzQr3ZQ/link-44052.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1386003.21&type=3&subid=0',
        type: ['health & beauty', 'home & living', 'department store']
    },
    {
        id: 163,
        title: 'Springboard',
        subtitle: 'Online Bootcamps for Coding, Design, Data Science and More. All With a Job Guarantee.',
        imageUrl: 'https://i.ibb.co/stQbFDH/Rak-Springboard-Logo.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1346512.51&type=3&subid=0',
        type: ['internet & online', 'business & career']
    },
    {
        id: 164,
        title: 'Athletic Propulsion Labs',
        subtitle: `From October 1, 2022 through September 30, 2023, APL is donating 20% of the sales price of the Streamline Fusion Pink / White / BCA to the Women's Cancer Research Fund, A Breast Cancer Research Foundation Program (BCRF).`,
        imageUrl: 'https://i.ibb.co/HpZ0r6G/link-41446.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=677023.45&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness']
    },
    {
        id: 165,
        title: 'Photobook Worldwide Sdn Bhd (US & CA)',
        subtitle: 'Shop Personalized Home Décor at Photobook America!',
        imageUrl: 'https://i.ibb.co/P9LW4gH/link-39025.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=701215.3921&type=3&subid=0',
        type: ['hobbies & collectibles', 'entertainment', 'gift & flowers', 'home & living']
    },
    {
        id: 166,
        title: 'Pure TheraPro Rx',
        subtitle: 'Doctor-Quality Dietary Supplements - USA Tested & Patented Formulas - ZERO Chinese Ingredients',
        imageUrl: 'https://i.ibb.co/8NRPDfg/link-50275.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1287150.23&type=3&subid=0',
        type: ['health & beauty']
    },
    {
        id: 167,
        title: 'Jones New York',
        subtitle: `Looking for chic and comfortable clothing? Check out Jones New York's collection today!`,
        imageUrl: 'https://i.ibb.co/dKKftGd/link-50401.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1314431.8&type=3&subid=0',
        type: ['clothing & accessories', 'gift & flowers', 'department store']
    },
    {
        //pinterest
        id: 168,
        title: 'Currex',
        subtitle: 'Get 10% Off at Currex with Newsletters Sign-Up',
        imageUrl: 'https://i.ibb.co/Ry6wbGN/link-50525.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1348170.3&type=3&subid=0',
        type: ['clothing & accessories', 'sports & fitness', 'health & beauty']
    },
    {
        id: 169,
        title: 'Pressed Juicery US',
        subtitle: 'Free Local Delivery on orders $50+',
        imageUrl: 'https://i.ibb.co/FspJp1T/link-50541.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1387485.8&type=3&subid=0',
        type: ['food & drink', 'miscellaneous']
    },
    {
        id: 170,
        title: 'Moussy',
        subtitle: 'Sign-up & Save 15% on Your Order. Save on Made in Japan Jeans & More',
        imageUrl: 'https://i.ibb.co/jR35C6P/link-50611.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1367673.7&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        // pinterest
        id: 171,
        title: 'YouTheory',
        subtitle: 'Free Shipping On All Orders $50+ with YouTheory',
        imageUrl: 'https://i.ibb.co/ThD9nZj/link-49995.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1209311.5&type=3&subid=0',
        type: ['health & beauty', 'miscellaneous']
    },
    {
        // pinterest
        id: 172,
        title: 'The Park Prodigy',
        subtitle: 'Free Disney and Universal Vacation Planning Tools',
        imageUrl: 'https://i.ibb.co/gDhtZ4N/link-48105.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1306363.20&type=3&subid=0',
        type: ['travel']
    },
    {
        // pinterest
        id: 173,
        title: 'Hayneedle',
        subtitle: 'Shop Hayneedle for all of your indoor & outdoor home furnishing needs',
        imageUrl: 'https://i.ibb.co/6Hkf52t/link-47774.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1071172.3&type=3&subid=0',
        type: ['home & living', 'office']
    },
    {
        // pinterest
        id: 174,
        title: 'LYS Beauty',
        subtitle: 'Shop at LYS Beauty Today. INCLUSIVE. CLEAN. CRUELTY FREE. 100% VEGAN.',
        imageUrl: 'https://i.ibb.co/BLpd716/link-46983.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1210900.6&type=3&subid=0',
        type: ['clothing & accessories', 'gift & flowers', 'health & beauty', 'department store']
    },
    {
        // pinterest
        id: 175,
        title: 'Peace Out',
        subtitle: 'Sign Up For Email Newsletters & Get 10% Off Your First Order!',
        imageUrl: 'https://i.ibb.co/0mTPJf8/link-45744.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=773886.13&type=3&subid=0',
        type: ['clothing & accessories', 'health & beauty', 'home & living', 'miscellaneous']
    },
    {
        // pinterest
        id: 176,
        title: 'Carmen Sol',
        subtitle: 'Sign up and Receive 10% Off Your First Purchase',
        imageUrl: 'https://i.ibb.co/cQLRZ9D/link-45692.jpg',
        link: 'https://click.linksynergy.com/fs-bin/click?id=vZvaHMsFWAQ&offerid=1295429.115&type=3&subid=0',
        type: ['clothing & accessories', 'department store']
    },
    {
        id: 177,
        title: 'LuckyTail',
        subtitle: 'Free Shipping on Orders Over $60 Code LUCKYPETSHIP',
        imageUrl: 'https://i.ibb.co/9qft4Q1/link-50743.png',
        link: 'https://click.linksynergy.com/fs-bin/click?id=rtoDsgPwfzU&offerid=1391419.17&type=3&subid=0',
        type: ['entertainment', 'home & living', 'department store']
    },
    // {
    //     id: 178,
    //     title: '',
    //     subtitle: '',
    //     imageUrl: '',
    //     link: '',
    //     type: ['']
    // },
    // {
    //     id: 179,
    //     title: '',
    //     subtitle: '',
    //     imageUrl: '',
    //     link: '',
    //     type: ['']
    // },
    // {
    //     id: 180,
    //     title: '',
    //     subtitle: '',
    //     imageUrl: '',
    //     link: '',
    //     type: ['']
    // },
]

export default categories;