import styled, { keyframes } from 'styled-components';

const audioWave = keyframes`
    0% {
        height: 6px;
        transform: translateY(0px);
        background: #ff8e3a;
    }

    25% {
        height: 40px;
        transform: translateY(-5px) scaleY(1.7);
        background: #ed509e;
    }

    50% {
        height: 6px;
        transform: translateY(0px);
        background: #9c73f8;
    }

    100% {
        height: 6px;
        transform: translateY(0px);
        background: #0fccce;
    }
`;

export const SoundwaveContainer = styled.div`
    background-color: #0e0e0e;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    width: 80%;
`;

export const Sound = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 60px;
    height: 40px;
`;

export const Wave = styled.div`
    height: 40px;
    display: block;
    width: 10px;
    height: 6px;
    border-radius: 8px;
    background: orange;
    animation: ${audioWave} 2.2s infinite ease-in-out;

    &:nth-child(1) {
        left: 11px;
        animation-delay: 0.2s;
    }

    &:nth-child(2) {
        left: 22px;
        animation-delay: 0.4s;
    }

    &:nth-child(3) {
        left: 33px;
        animation-delay: 0.6s;
    }

    &:nth-child(4) {
        left: 44px;
        animation-delay: 0.8s;
    }

    &:nth-child(5) {
        left: 55px;
        animation-delay: 1s;
    }
`;