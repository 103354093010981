import styled from 'styled-components';

export const DurationCalculatorContainer = styled.div`
`;

export const LineContainer = styled.div`
    padding: 5px;
`;

export const LabelText = styled.label`
    font-size: 30px;
`;

export const TimeInput = styled.input`
    font-size: 24px;
    margin: 5px;
`;

export const BreakInput = styled.input`
    font-size: 24px;
    margin: 5px;
    margin-left: 25px;
    width: 25%;
`;

export const CheckBox = styled.input`
    width: 24px;
    height: 24px;
    transform: translateY(15%);
    margin-right: 25px;
`;

export const CalculateBtn = styled.button`
    border: none;
    color: white;
    background-color: #00beef;
    padding: 15px;
    font-size: 24px;
    border-radius: 5px;
    margin-top: 15px;

    &:hover {
        background-color: #00aad6;
    }
`;
