import './category-item.styles.scss';

const CategoryItem = ({ category }) => {
    
    const { imageUrl, title, subtitle, link } = category;
    
    return (
        <div className='category-container'>
            <div className='background-image' style={{
              backgroundImage: `url(${imageUrl})`
            }}/>
            <div className='category-body-container'>
              <a className='category-link' href={link}>
                <h2>{title}</h2>
                <h5>{subtitle}</h5>
                <p>Show Now</p>
              </a>
            </div>
        </div>
    );
}

export default CategoryItem;