import { Outlet } from 'react-router-dom';

import LandingPage from "../../components/landing-page/landing-page.components";
import ProjectHighlight from "../../components/project-highlight/project-highlight.components";

const Home = () => {
    return (
        <div>
            <LandingPage />
            <ProjectHighlight />
            <Outlet />
        </div>
    )
}

export default Home;