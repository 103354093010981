const skills = [
    {
        id: 1,
        name: 'HTML',
        icon: 'html5'
    },
    {
        id: 2,
        name: 'CSS',
        icon: 'css3'
    },
    {
        id: 3,
        name: 'JavaScript',
        icon: 'javascript'
    },
    {
        id: 4,
        name: 'React',
        icon: 'react'
    },
    {
        id: 5,
        name: 'Bootstrap',
        icon: 'bootstrap'
    },
    {
        id: 6,
        name: 'Material-UI',
        icon: 'materialui'
    },
    {
        id: 7,
        name: 'Node',
        icon: 'nodejs'
    },
    {
        id: 8,
        name: 'Express',
        icon: ''
    },
    {
        id: 9,
        name: 'MongoDB',
        icon: 'mongodb'
    },
    {
        id: 10,
        name: 'mongoose',
        icon: ''
    },
    {
        id: 11,
        name: 'PostgreSQL',
        icon: 'postgresql'
    },
    {
        id: 12,
         name: 'C++',
        icon: 'cplusplus'
    },
    {
        id: 13,
        name: 'Java',
        icon: 'java'
    },
    {
        id: 14,
        name: 'C#',
        icon: 'csharp'
    },
    {
        id: 15,
        name: 'Kotlin',
        icon: 'kotlin'
    },
    {
        id: 16,
        name: 'Python',
        icon: 'python'
    },
    {
        id: 17,
        name: '.NET',
        icon: 'dot-net'
    },
    {
        id: 18,
        name: '.NET Core',
        icon: 'dotnetcore'
    },
    {
        id: 19,
        name: 'ASP.NET',
        icon: ''
    },
    {
        id: 20,
        name: 'GitHub',
        icon: 'github'
    },
    {
        id: 21,
        name: 'IntelliJ',
        icon: 'intellij'
    },
    {
        id: 22,
        name: 'npm',
        icon: ''
    },
    {
        id: 23,
        name: 'Raspberry Pi',
        icon: ''
    },
    {
        id: 24,
        name: 'Arduino',
        icon: 'arduino'
    },
    {
        id: 25,
        name: 'sass',
        icon: 'sass'
    },
    {
        id: 26,
        name: 'vs code',
        icon: 'vscode'
    },
    {
        id: 27,
        name: 'npm',
        icon: ''
    },
    {
        id: 28,
        name: 'Firebase',
        icon: 'firebase'
    },
    {
        id: 29,
        name: 'Redux',
        icon: 'redux'
    },
    {
        id: 29,
        name: 'Next.js',
        icon: 'nextjs'
    },
    {
        id: 30,
        name: 'Scratch',
        icon: ''
    },
    {
        id: 31,
        name: 'React Native',
        icon: ''
    },
    {
        id: 32,
        name: '.NET MAUI',
        icon: ''
    },
    {
        id: 33,
        name: 'Android Studio',
        icon: 'androidstudio'
    },
    {
        id: 34,
        name: 'Azure',
        icon: 'azure'
    },
    {
        id: 35,
        name: 'Canva',
        icon: 'canva'
    },
]

export default skills;