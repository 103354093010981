import styled from 'styled-components';

export const CounterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 50px;
    border-radius: 15px;
    background-color: whitesmoke;
`;

export const CounterBtn = styled.button`
    width: 60px;
    height: 60px;
    margin: 20px;
    font-size: 40px;
    background-color: #00beef;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;

    &:hover {
        background: #00aad6;
    }

    &:active {
        transform: translateY(2px);
    }

    @media (max-width: 768px) {
        font-size: 24px;
        width: 40px;
        height: 40px;
    }
`;

export const Count = styled.span`
    font-size: 40px;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

export const ResetBtn = styled.button`
    background-color: #ef3100;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
    margin-top: 15px;
    cursor: pointer;

    &:hover {
        background: #d62c00;
    }

    &:active {
        transform: translateY(2px);
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

export const MainDisplay = styled.div`

`;