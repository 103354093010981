import { Link } from 'react-router-dom';

import './footer.styles.scss';

import logo from '../../assets/logo.png';
import NewsletterSignup from '../newsletter-signup/newsletter-signup.components';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='links-container'>
                <Link className='logo-container' to='/'>
                    <img src={logo} className='logo' alt='Logo' />
                </Link>
                <div className='page-links-container'>
                    <h3 className='link-title'>Page Links</h3>
                    <Link className='footer-link' to='/'>
                        HOME
                    </Link>
                    <Link className='footer-link' to='/about'>
                        ABOUT
                    </Link>
                    <Link className='footer-link' to='/apps'>
                        APPS
                    </Link>
                    <Link className='footer-link' to='/projects'>
                        PROJECTS
                    </Link>
                    <Link className='footer-link' to='/shop'>
                        AFFILIATES
                    </Link>
                    <Link className='footer-link' to='/contact'>
                        CONTACT
                    </Link>
                    <Link className='footer-link' to='/play'>
                        PLAY
                    </Link>
                </div>
                <div className='social-links-container'>
                    <h3 className='link-title'>Social Links</h3>
                    <Link className='footer-link' to='https://www.linkedin.com/in/danielwernz'>
                        LinkedIn
                    </Link>
                    <Link className='footer-link' to='https://www.github.com/dwernz'>
                        GitHub
                    </Link>
                    <Link className='footer-link' to='https://www.instagram.com/danielwernz'>
                        Instagram
                    </Link>
                </div>
            </div>

            <NewsletterSignup />

            <hr className='divider-copyright' />
            <div className='copyright'>
                <p>&copy;2023 - Daniel Wernz. All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer;