import styled from 'styled-components';

export const ButtonContainer = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Mulish;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Background = styled.span`
    background-color: #2c2c2c;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 20vw;
    border-radius: 8px;
`;

export const Button = styled.a`
    color: #ffffff;
    cursor: pointer;
    font-size: 1rem;
    line-height: 2.5rem;
    max-width: 160px;
    width: 100%;
    letter-spacing: 0.3rem;
    font-weight: 600;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    transition: all .5s ease-in;

    &:hover {
        color: #ff9950;
    }
`;

export const Svg = styled.svg`
    height: 40px;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
`;

export const Rect = styled.rect`
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 450, 0;
    transition: all 0.5s linear;

    ${Button}:hover & {
        stroke-width: 5;
        stroke-dasharray: 20, 300;
        stroke-dashoffset: 48;
        stroke: #FF9950;
        transition: all 2s cubic-bezier(
            0.22, 1, 0.25, 1
        );
    }
`;