import Counter from '../../components/play/counter/counter.component';
import DurationCalculator from '../../components/play/duration-calculator/duration-calculator.component';
import ToDoList from '../../components/play/todo-list/todo-list.component';

import { AppsContainer, AppContainer, AppDescription } from './apps.styles';

const Apps = () => {
    return (
        <AppsContainer>
            <AppContainer>
                <h3>Counter</h3>
                <AppDescription>This is a basic counter app that users can increment or decrement the count by using the appropriate button, or reset it. This is a warm up of using useState with react. Rather than using the DOM, React uses a virtual DOM to optimize rendering.</AppDescription>
                <Counter />
            </AppContainer>

            <AppContainer>
                <h3>To Do List</h3>
                <AppDescription>Created a To Do List app for users to add and remove items from their to do lists. This used useState to handle the array of to do list items, and useRef to handle the input.</AppDescription>
                <ToDoList />
            </AppContainer>

            <AppContainer>
                <h3>Duration Calculator</h3>
                <AppDescription>This was inspired by my wife consistently asking me to calculate the amount of time she worked. Users can input their start time, end time, and break time to calculate the amount of time that has passed.</AppDescription>
                <DurationCalculator />
            </AppContainer>
            
        </AppsContainer>
    )
}

export default Apps;