import styled from "styled-components";

const background = "#2c2c2c";

export const CardContainer = styled.div`
    margin: 0;
    padding: 0;
    background-color: ${background};
    font-family: Mulish;
    display: grid;
    place-items: center;
    height: 60vh;
    padding: 24px;
    font-family: Poppins, sans-serif;
    z-index: 0;
    position: relative;
`;

export const Card = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px 20px 16px 16px;
    background-color: white;
    cursor: pointer;
    overflow: hidden;
    max-width: clamp(620px, 70vw, 820px);
    min-width: 260px;
    min-height: 280px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
`;

export const Background = styled.div`
    flex: 1 1 250px;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const BackgroundImg = styled.img`
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: scale-down;
`;

export const Content = styled.div`
    flex: 3 1 350px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 16px;
`;

export const Header2 = styled.h2`
    font-size: clamp(1.3rem, 2.5vw, 1.8rem);
    font-weight: 700;
    margin-bottom: clamp(0.35rem, 2vw, 0.55rem);
`;

export const Paragraph = styled.p`
    font-size: clamp(1.1rem, 1.75vw, 1.35rem);
    font-weight: 400;
    margin-bottom: 16px;
    line-height: 22px;
`;

export const SocialIcons = styled.ul`
    display: flex;
    width: 100%;
    justify-content: start;
    margin-top: auto;
    gap: clamp(0.4rem, 2vw, 0.8rem);
`;

export const SocialIcon = styled.li`
    list-style: none;
`;

export const SocialIconAnchor = styled.a`
    position: relative;
    display: block;
    display: grid;
    place-items: center;
    font-weight: bold;
    transition: .2s;
`;

export const SocialIconSVG = styled.svg`
    ${SocialIconAnchor}:hover & {
        transition: 1.2s;
        transform: rotateY(360deg);
        color: #00beef;
    }
`;