import { Routes, Route, Navigate} from 'react-router-dom';

import Navigation from './components/navigation/navigation.components';
import Footer from './components/footer/footer.component';
import Home from './routes/home/home.components';
import About from './routes/about/about.components';
import Projects from './routes/projects/projects.components';
import Shop from './routes/shop/shop.components';
import Contact from './routes/contact/contact.components';
import ScrollToTop from './components/scroll-to-top/scroll-to-top.components';
import Play from './components/play/play.components';
import Apps from './routes/apps/apps.component';
import Affiliates from './routes/affiliates/affiliates.component';
import Services from './routes/services/services.component';


const App = () => {
  /*  Todo
      _________________________
      Blog
        Coding
        Product reviews
        Butterflies
      Services
        Single page template
        single page custom
        multipage template
        multipage custom
        frontend only
        backend included
      Games
        Simon
        Math Quiz
        Tic Tac Toe
        Pong
        Rock Paper Scissors
      __________________________

      Started working on a templates website.

  */
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={ <div><Navigation /> <Footer /></div> }>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='apps' element={<Apps />} />
          <Route path='projects' element={<Projects />} />
          <Route path='shop' element={<Shop />} />
          <Route path='contact' element={<Contact />} />
          <Route path='play' element={<Play />} />
          <Route path='affiliates/*' element={<Affiliates />} />
          <Route path='services/*' element={<Services />} />
          {/* Any other path, just go to home page. */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </div>
    
  );
}

export default App;
