import { useState } from 'react';
import { CounterContainer, CounterBtn, Count, ResetBtn, MainDisplay } from './counter.styles';

const Counter = () => {
    const [count, setCount] = useState(0);

    const decrement = () => {
        setCount(count - 1);
    };

    const increment = () => {
        setCount(count + 1);
    };

    const reset = () => {
        setCount(0);
    };

    return (
        <CounterContainer>
            <MainDisplay>
                <CounterBtn onClick={decrement}>-</CounterBtn>
                <Count>{count}</Count>
                <CounterBtn onClick={increment}>+</CounterBtn>
            </MainDisplay>
            
            <ResetBtn onClick={reset}>Reset Count</ResetBtn>
        </CounterContainer>
    )
}

export default Counter;