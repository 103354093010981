import styled from 'styled-components';



export const ScrollToTopBtn = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    opacity: 0.5;
    font-size: 24px;
    background: #69abcf;
    color: white;
    padding: 25px;
    border: none;
    border-radius: 15px;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }

    ${props => props.isVisible && `
        display: block;
    `}
`;

