import SectionHeader from "../section-header/section-header.components";
import CardSkeleton from "./card-skeleton/card-skeleton.components";
import Card from "./card/card.components";
import GlowingMenu from "./glowing-menu/glowing-menu.components";
import HoverButton from "./hover-button/hover-button.components";
import LoadingMeatballs from "./loading-meatballs/loading-meatballs.components";
import Modal from "./modal/modal.components";
import ResponsiveCard from "./responsive-card/responsive-card.components";

import './play.styles.scss';
import HoverCards from "./hover-cards/hover-cards.components";
import MenuBlur from "./menu-blur/menu-blur.components.components";
import Footer1 from "./footer-1/footer.component";
import Flowers from "./flowers/flowers.component";
import NavbarMuhilan from "./navbar-muhilan/navbar-muhilan.component";
import Soundwave from "./soundwave/soundwave.component";
import MuhilanButton from "./muhilan-button/muhilan-button.component";
import BootstrapButton from "../templates/buttons/bootstrap-button/bootstrap-button.component";

const Play = () => {
    return (
        <div className='play-container'>
            <SectionHeader 
                title='Play'
                text='This is an area dedicated to the development, testing, and modification of components that can be used in future projects'
            />

            <p className="text-center play-title">Bootstrap Buttons</p>
            <div className='bootstrap-button-container'>
                <BootstrapButton text="Primary" theme="primary" />
                <BootstrapButton text="Secondary" theme="secondary" />
                <BootstrapButton text="Success" theme="success" />
                <BootstrapButton text="Danger" theme="danger" />
                <BootstrapButton text="Warning" theme="warning" />
                <BootstrapButton text="Info" theme="info" />
                <BootstrapButton text="Light" theme="light" />
                <BootstrapButton text="Dark" theme="dark" />
                
            </div>
            <div className='bootstrap-button-container'>
                <BootstrapButton text="Primary" theme="outline-primary" />
                <BootstrapButton text="Secondary" theme="outline-secondary" />
                <BootstrapButton text="Success" theme="outline-success" />
                <BootstrapButton text="Danger" theme="outline-danger" />
                <BootstrapButton text="Warning" theme="outline-warning" />
                <BootstrapButton text="Info" theme="outline-info" />
                <BootstrapButton text="Light" theme="outline-light" />
                <BootstrapButton text="Dark" theme="outline-dark"/>
            </div>
            <div className='bootstrap-button-container'>
                <BootstrapButton text="Primary" theme="primary" size="small" />
                <BootstrapButton text="Primary" theme="primary" />
                <BootstrapButton text="Primary" theme="primary" size="large" />
            </div>
            <div className='bootstrap-button-container'>
                <BootstrapButton text="Secondary" theme="secondary" size="small" />
                <BootstrapButton text="Secondary" theme="secondary" />
                <BootstrapButton text="Secondary" theme="secondary" size="large" />
            </div>
            
            <hr className="play-divider" />

            <p className="text-center play-title">Juliocodes Cards/06</p>
            <Card className='play-component' />
            <hr className="play-divider" />
            
            <p className="text-center play-title">Coding.Stella Glowing Navigation Menu</p>
            <GlowingMenu className='play-component' />
            <hr className="play-divider" />
            
            
            <p className="text-center play-title">codewithshobhit Pure CSS Popup Box</p>
            <Modal />
            <hr className="play-divider" />

            <p className="text-center play-title">davidm_ai Hover Button</p>
            <HoverButton />
            <hr className="play-divider" />

            <p className='text-center play-title'>juliocodes Bouncy Loading Animation</p>
            <LoadingMeatballs />
            <hr className="play-divider" />

            <p className='text-center play-title'>codeatnow Card Skeleton</p>
            <CardSkeleton />
            <hr className="play-divider" />

            <p className='text-center play-title'>coding_dev Responsive Card used as a base, but the provided code does not yield the desired results.</p>
            <ResponsiveCard />
            <hr className="play-divider" />

            <p className='text-center play-title'>davidm_ai Hover Cards</p>
            <HoverCards />
            <hr className="play-divider" />

            <p className='text-center play-title'>coderlab.dev Menu Hover Effect</p>
            <MenuBlur />
            <hr className="play-divider" />

            <p className='text-center play-title'>davidm_ai Footer</p>
            <Footer1 />
            <hr className="play-divider" />

            {/* <p className='text-center play-title'>Snake Game</p>
            <Snake />
            <hr className="play-divider" /> */}

            <p className='text-center play-title'>Flowers by jeycaarce</p>
            <Flowers />
            <hr className="play-divider" />

            <p className='text-center play-title'>Navbar with codewith_muhilan</p>
            <NavbarMuhilan />
            <hr className="play-divider" />

            <p className='text-center play-title'>Soundwave Animation with davidm_ai</p>
            <Soundwave />
            <hr className="play-divider" />

            <p className='text-center play-title'>Shine Buttons with Muhilan</p>
            <MuhilanButton />
            <hr className="play-divider" />

        </div>
    )
}

export default Play;