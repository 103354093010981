import CategoryItem from '../category-item/category-item.components';

import './directory.styles.scss';

const Directory = ({categories}) => {
    return (
        <div className="directory-container">
            {
                categories.map((category) => (
                    <CategoryItem key={category.id} category={category} subtitle={category.subtitle} link={category.link}/>
                ))
            }
            
        </div>
    )
}

export default Directory;