import { Background, BackgroundImg, Card, CardContainer, Content, Header2, Paragraph, SocialIcon, SocialIconAnchor, SocialIconSVG, SocialIcons } from './davidmai-card-2.style';

import picture from '../../../../assets/profile-pic.jpg';

const DavidmaiCard2 = () => {
    return (
        <CardContainer>
            <Card>
                <Background>
                    <BackgroundImg src={picture} />
                </Background>
                <Content>
                    <Header2>Daniel Wernz</Header2>
                    <Paragraph>
                        I'm a web developer, love working with <em>React</em> and <em>Node.js</em>.<br />
                        Outside of coding, I love to scuba dive, and read.
                    </Paragraph>
                    <SocialIcons>
                        <SocialIcon>
                            <SocialIconAnchor href="#" title='social media'>
                                <SocialIconSVG xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                     stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"
                                >
                                    <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 
                                            10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5
                                            4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                                    >
                                    </path>
                                </SocialIconSVG>
                            </SocialIconAnchor>
                        </SocialIcon>
                        <SocialIcon>
                            <SocialIconAnchor href="#instagram" title="Instagram">
                                <SocialIconSVG xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                     stroke-linecap="round" stroke-linejoin="round"
                                >
                                    <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                </SocialIconSVG>
                            </SocialIconAnchor>
                        </SocialIcon>
                        <SocialIcon>
                            <SocialIconAnchor href="#threads" title="Threads">
                                <SocialIconSVG xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" 
                                     fill="none" stroke-linecap="round" stroke-linejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M19 7.5c-1.333 -3 -3.667 -4.5 -7 -4.5c-5 0 -8 2.5 -8 9s3.5 9 8 9s7 -3 7
                                            -5s-1 -5 -7 -5c-2.5 0 -3 1.25 -3 2.5c0 1.5 1 2.5 2.5 2.5c2.5 0 3.5 -1.5 
                                            3.5 -5s-2 -4 -3 -4s-1.833 .333 -2.5 1"
                                    >
                                    </path>
                                 </SocialIconSVG>
                            </SocialIconAnchor>
                        </SocialIcon>
                        <SocialIcon>
                            <SocialIconAnchor href="#linkedin" title="Linkedin">
                                <SocialIconSVG xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
                                     className="feather feather-linkedin"
                                >
                                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/>
                                    <rect x="2" y="9" width="4" height="12"/><circle cx="4" cy="4" r="2"/>
                                </SocialIconSVG>
                            </SocialIconAnchor>
                        </SocialIcon>
                    </SocialIcons>
                </Content>
            </Card>
            <p>Davidm_ai Profile Card 2</p>
        </CardContainer>
    )
}

export default DavidmaiCard2;