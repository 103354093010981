import AffiliatesPreview from './affilaite-preview/affilaite-preview.component';
import Affiliate from './affiliate/affiliate.component';
import {} from './affiliates.styles';
import { Routes, Route } from 'react-router-dom';

const Affiliates = () => {
    return (
        <Routes>
            <Route index element={<AffiliatesPreview />} />
            <Route path=':id' element={<Affiliate />} />
        </Routes>
    )
}

export default Affiliates;