import './responsive-card.styles.scss';

const ResponsiveCard = () => {
    return (
        <div className='responsive-card'>
            <div className='thumb'></div>
            <div className='responsive-content'>
                <h3>Title</h3>
                <p>Some description</p>
            </div>
        </div>
    )
}

export default ResponsiveCard;