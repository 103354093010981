import styled from 'styled-components';

export const NavbarMuhilanContainer = styled.div`
    border: 1px solid black;
    background: #252525;
    width: 90%;
`;

export const List = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: center;
`;

export const Link = styled.a`
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #00fffc;
        transform: scaleX(0);
        transition: transform 0.25s;
    }

    &::before {
        top: -3px;
        transform-origin: left;
    }

    &::after {
        bottom: -3px;
        transform-origin: right;
    }

    &:hover::before,
    &:hover::after,
    &:active::before,
    &:active::after {
        transform: scaleX(1);
        cursor: pointer;
    }
`;